import { TableViewHeader } from '@services/ui-services/models';
import { TopCustomerStats } from './models';

type mapOrderTableRow = TopCustomerStats & {
  isSelected?: boolean;
};

type TableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapOrderTableRow;
};

export const TopCustomerList: TableHead[] = [
  {
    type: 'string',
    label: 'Customer',
    sortable: true,
    isShow: true,
    column: 'name',
    sortOrder: 'default',
  },
  {
    type: 'number',
    label: 'Orders',
    sortable: true,
    isShow: false,
    column: 'order_count',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Total Order value',
    sortable: false,
    isShow: true,
    column: 'total_order_value',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Avg Order value',
    sortable: false,
    isShow: true,
    column: 'avg_order_value',
    sortOrder: 'default',
  },
  {
    type: 'actions',
    label: '',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
  },
];
