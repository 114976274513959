import { Component, OnDestroy } from '@angular/core';
import { AnalyticsRangeContext, ComparePeriod } from '@services/analytics-services/core';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';
import { DashboardStore } from '../dashboard/dashboard.store';
import { AsyncPipe } from '@angular/common';
import { OrdersAnalyticsCharts } from '@services/analytics-services/orders/orders-analytics.charts';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { ProcessingState, Pagination } from '@services/core-services/models';
import { TableView } from '@services/ui-services/models';
import { Router } from '@angular/router';
import { ListFooterComponent } from '@enginuity/core/organisms/list-footer/list-footer.component';
import { DashboardorderList } from '@services/order-services/constants/dashboard-order-table-header.constant';
import { mapDashboardOrderTableData } from '@services/order-services/constants/dashboard-order.table.mapper';
import { ChartContainerComponent } from '@enginuity/analytics/organisms/chart-container/chart-container.component';
import type { GroupedTabData } from '@services/analytics-services/core/group-chart.modals';

@Component({
  selector: 'app-dashboard-orders',
  imports: [
    AnalyticsTrendWidgetComponent,
    AsyncPipe,
    TableViewComponent,
    ListFooterComponent,
    ChartContainerComponent,
  ],
  templateUrl: './dashboard-orders.component.html',
  styleUrl: './dashboard-orders.component.scss',
  providers: [OrdersAnalyticsCharts],
})
export class DashboardOrdersComponent implements OnDestroy {
  protected orderAnalytics$;
  protected comparePeriod!: ComparePeriod;
  protected context: AnalyticsRangeContext | undefined;
  private destroy$ = new Subject<void>();
  public orderLoader$ = new Observable<ProcessingState>();
  public orderErrorMessage$: Observable<string>;

  associatedCampaignsStats$: Observable<GroupedTabData>;
  associatedCampaignsTabs$;
  associatedCampaignsTabsActiveTab$: Observable<string>;
  associatedCampaignsPagination$: Observable<any>;

  // Title arrays for different chart sections
  protected associatedCampaignsTitles: string[] = ['User count'];

  protected pagination: Pagination | undefined;
  protected orderTable: TableView = {
    head: DashboardorderList,
    rows: [],
    data: [],
  };

  constructor(
    private readonly dashboardStore: DashboardStore,
    private router: Router
  ) {
    this.dashboardStore.getComparePeriod().subscribe(period => (this.comparePeriod = period));
    this.dashboardStore.getRangeContext().subscribe(context => this.fetchAnalytics(context));
    this.orderAnalytics$ = this.dashboardStore.getOrderAnalytics();
    this.orderErrorMessage$ = this.dashboardStore.getErrorMessage();
    this.orderLoader$ = this.dashboardStore.getOrderLoader();

    this.associatedCampaignsStats$ = this.dashboardStore.getassociatedCampaignsStats();
    this.associatedCampaignsTabs$ = this.dashboardStore.getassociatedCampaignsTabs();
    this.associatedCampaignsTabsActiveTab$ =
      this.dashboardStore.getassociatedCampaignsTabsActiveTab();
    this.associatedCampaignsPagination$ = this.dashboardStore.getassociatedCampaignsPagination();

    this.dashboardStore
      .getOrderList()
      .pipe(
        takeUntil(this.destroy$),
        tap(({ list, pagination }) => {
          this.orderTable.rows = list.map(order => mapDashboardOrderTableData(order));
          this.pagination = pagination;
        })
      )
      .subscribe();

    this.dashboardStore.loadAssociatedCampaignsData().subscribe();
  }

  private fetchAnalytics(context: AnalyticsRangeContext) {
    this.context = context;
    this.dashboardStore.loadOrderAnalytics().subscribe();
    this.dashboardStore.loadDashboardOrders().subscribe();
    this.dashboardStore.loadAssociatedCampaignsData().subscribe();
  }

  onActionClick(entry: any) {
    if (entry.action == 'order-detail') {
      this.router.navigateByUrl(`products/${entry.orderId}/summary`);
    }
  }

  onPageChange(page: number) {
    this.dashboardStore.paginateOrders(page).subscribe();
  }

  protected onAssociatedCampaignsTabChange(tabId: string): void {
    this.dashboardStore.updateAssociatedCampaignsDefaultTab(tabId);
  }

  protected onAssociatedCampaignsPageChange(event: {
    tabId: string;
    page: number;
    limit: number;
  }): void {
    this.dashboardStore.updateAssociatedCampaignsPagination(event.tabId, event.page, event.limit);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
