import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-analytics-widget-host',
  standalone: true,
  imports: [NgIf],
  templateUrl: './analytics-widget-host.component.html',
  styleUrl: './analytics-widget-host.component.scss',
})
export class AnalyticsWidgetHostComponent {
  @Input() headingTitle: string = '';
  @Input() height: number | 'auto' = 'auto';
  @Input() subtitle: string = '';
}
