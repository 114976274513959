import { Injectable } from '@angular/core';
import {
  AnalyticsInterval,
  analyticsMetricsTransformer,
  AnalyticsRangeContext,
  DefaultChartConfigService,
} from '@services/analytics-services/core';
import { map, Observable } from 'rxjs';
import { DateRangePresetType } from '@services/core-services/models';
import { SalesAnalyticsService } from '@services/analytics-services/sales/sales-analytics.service';
import { CurrencyPipe } from '@angular/common';
import { ChartConfiguration } from 'chart.js';

@Injectable()
export class SalesAnalyticsCharts {
  constructor(
    private readonly currencyPipe: CurrencyPipe,
    private readonly chartConfigService: DefaultChartConfigService,
    private readonly salesAnalyticsService: SalesAnalyticsService
  ) {}

  getNetAndGrossSalesMetricsChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    const currencyPipe = this.currencyPipe;
    return this.salesAnalyticsService.getNetAndGrossSalesMetrics(context).pipe(
      map(({ list }) => {
        const transformer = analyticsMetricsTransformer(context.interval as AnalyticsInterval, {
          net_sales: 'Net Sales',
          gross_sales: 'Gross Sales',
        });

        return this.chartConfigService.getChartConfig('line', list, transformer, {
          scales: {
            y: {
              ticks: {
                callback: function (value) {
                  return currencyPipe.transform(value);
                },
              },
            },
          },
        });
      })
    );
  }

  getOrdersOverTimeChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    const { range, interval, preset } = context;
    return this.salesAnalyticsService
      .getOrdersOverTime({
        preset: preset as DateRangePresetType,
        range,
        interval,
      })
      .pipe(
        map(({ list }) => {
          const transformer = analyticsMetricsTransformer(context.interval as AnalyticsInterval, {
            order_count: 'No of Orders',
          });

          return this.chartConfigService.getChartConfig('line', list, transformer);
        })
      );
  }
}
