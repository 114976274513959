import { Component, OnDestroy } from '@angular/core';
import { AnalyticsRangeContext, ComparePeriod } from '@services/analytics-services/core';
import { DashboardStore } from '../dashboard/dashboard.store';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';
import { AsyncPipe } from '@angular/common';
import { AnalyticsWidgetHostComponent } from '@enginuity/analytics/organisms/analytics-widget-host/analytics-widget-host.component';
import { Subject, takeUntil, tap } from 'rxjs';
import { ShippingAnalyticsService } from '@services/analytics-services/shipping/shipping-analytics.service';
import { HighChartContainerComponent } from '@enginuity/analytics/organisms/high-chart-container/high-chart-container.component';
import { ChartOptions } from '@services/analytics-services/hight-chart-configurations/high-chart-config';
import { ShippingAnalyticsCharts } from '@services/analytics-services/shipping/shipping-analytics.charts';
import { NotifyService } from '@services/core-services/notify.service';
import { ChartContainerComponent } from '@enginuity/analytics/organisms/chart-container/chart-container.component';
import { GroupedTabData } from '@services/analytics-services/core/group-chart.modals';
import { Observable } from 'rxjs';
import { STATUS_COLORS } from '@services/analytics-services/hight-chart-configurations';

@Component({
  selector: 'app-dashboard-shipment',
  imports: [
    AnalyticsTrendWidgetComponent,
    AsyncPipe,
    AnalyticsWidgetHostComponent,
    HighChartContainerComponent,
    ChartContainerComponent,
  ],
  templateUrl: './dashboard-shipment.component.html',
  styleUrl: './dashboard-shipment.component.scss',
  providers: [ShippingAnalyticsCharts],
})
export class DashboardShipmentComponent implements OnDestroy {
  protected shipmentAnalytics$;
  protected comparePeriod!: ComparePeriod;
  protected context: AnalyticsRangeContext | undefined;
  protected shippingSLAChartOptions: ChartOptions = {};
  protected shippingByCourierChart: { label: string; value: number }[] = [];
  protected averageDeliveryByCourierChart: { label: string; value: number }[] = [];
  protected CourierChartOptions: ChartOptions = {};
  protected AverageDeliveryOptions: ChartOptions = {};
  private destroy$ = new Subject<void>();
  protected StateData: any[] = [];

  stateWiseShipmentPerformanceStats$: Observable<GroupedTabData>;
  stateWiseShipmentPerformanceTabs$;
  stateWiseShipmentPerformanceTabsActiveTab$: Observable<string>;
  stateWiseShipmentPerformancePagination$: Observable<any>;

  // Title arrays for different chart sections
  protected stateWiseShipmentPerformanceTitles: string[] = [
    'Shipped',
    'Early',
    'On time',
    'Late',
    'Shipping time',
    'Transit time',
    'Delivery time',
  ];

  constructor(
    private readonly dashboardStore: DashboardStore,
    private readonly shippingAnalyticsService: ShippingAnalyticsService,
    private readonly shippingAnalyticsCharts: ShippingAnalyticsCharts,
    private notify: NotifyService
  ) {
    this.dashboardStore
      .getRangeContext()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: context => this.fetchAnalytics(context),
        error: err => {
          this.notify.error(err);
        },
      });
    this.shipmentAnalytics$ = this.dashboardStore.getShippingAnalytics();

    this.stateWiseShipmentPerformanceStats$ =
      this.dashboardStore.getstateWiseShipmentPerformanceStats();
    this.stateWiseShipmentPerformanceTabs$ =
      this.dashboardStore.getstateWiseShipmentPerformanceTabs();
    this.stateWiseShipmentPerformanceTabsActiveTab$ =
      this.dashboardStore.getstateWiseShipmentPerformanceTabsActiveTab();
    this.stateWiseShipmentPerformancePagination$ =
      this.dashboardStore.getstateWiseShipmentPerformancePagination();
  }

  private fetchAnalytics(context: AnalyticsRangeContext): void {
    this.context = context;

    // Load all data streams
    this.dashboardStore.loadStateWiseShipmentPerformanceData().subscribe();
    this.dashboardStore.loadVEventAnalytics().subscribe();
    this.dashboardStore.loadShippingAnalytics().subscribe();

    this.shippingAnalyticsService
      .getShippingSLAStats(context)
      .pipe(
        takeUntil(this.destroy$),
        tap(response => {
          const chartData = response.list.map(
            (item: { slo_status: string; shipment_count: number }) => ({
              label: item.slo_status,
              y: item.shipment_count,
              color: STATUS_COLORS[item.slo_status] || STATUS_COLORS['neutral'],
            })
          );

          this.shippingSLAChartOptions = {
            data: chartData,
            title: 'Delivery Status Distribution',
            showLegend: true,
            tooltipFormatter: (point: Highcharts.Point) => `
              <div class="custom-chart-tooltip" style="padding:12px;">
                <div class="d-flex flex-column spacing-8 align-items-start w-100">
                  <h4 class="h4-bold primary-0 text-capitalize" style="margin: 0">${point.name}</h4>
                  <span class="dash-boarder w-100"></span>
                  <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                    <div class="d-flex align-items-center spacing-4 w-100 text-center">
                      <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold">&nbsp;</div>
                      <div class="h4-reg primary-50">Shipments</div>
                    </div>
                    <div class="h4-bold primary-0">${point.y}</div>
                  </div>
                </div>
              </div>
            `,
          };
        })
      )
      .subscribe({ error: err => console.error('Error fetching shipping SLA stats:', err) });

    this.shippingAnalyticsCharts
      .getShippingByCourierCharts(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap((data: { label: string; value: number }[]) => {
          this.shippingByCourierChart = data;
          this.CourierChartOptions = {
            categories: this.shippingByCourierChart.map(item => item.label ?? 'null'),
            data: this.shippingByCourierChart.map(item => ({
              y: item.value,
              label: item.label,
            })),
            yAxisTitle: 'Number of Shipments',
            tooltipFormatter: (point: Highcharts.Point) => `
                <div class="custom-chart-tooltip" style="padding:12px;">
                  <div class="d-flex flex-column spacing-8 align-items-start w-100">
                    <h4 class="h4-bold primary-0 text-capitalize" style="margin: 0">${point.options.label}</h4>
                    <span class="dash-boarder w-100"></span>
                    <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                      <div class="d-flex align-items-center spacing-4 w-100 text-center">
                        <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold"> </div>
                        <div class="h4-reg primary-50">Shipped Order</div>
                      </div>
                      <div class="h4-bold primary-0">${point.options.y}</div>
                    </div>
                  </div>
                </div>
              `,
          };
        })
      )
      .subscribe({ error: err => console.error('Error loading chart data:', err) });

    this.shippingAnalyticsCharts
      .getAverageDeliveryByCourierCharts(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap((data: { label: string; value: number }[]) => {
          this.averageDeliveryByCourierChart = data;

          this.AverageDeliveryOptions = {
            categories: this.averageDeliveryByCourierChart.map(item => item.label ?? 'null'),
            data: this.averageDeliveryByCourierChart.map(item => ({
              y: item.value,
              label: item.label,
            })),
            yAxisTitle: 'Number of Average',
            tooltipFormatter: (point: Highcharts.Point) => `
                <div class="custom-chart-tooltip" style="padding:12px;">
                  <div class="d-flex flex-column spacing-8 align-items-start w-100">
                    <h4 class="h4-bold primary-0 text-capitalize" style="margin: 0">${point.options.label}</h4>
                    <span class="dash-boarder w-100"></span>
                    <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                      <div class="d-flex align-items-center spacing-4 w-100 text-center">
                        <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold"> </div>
                        <div class="h4-reg primary-50">Average Delivery Time</div>
                      </div>
                      <div class="h4-bold primary-0">${point.y} days</div>
                    </div>
                  </div>
                </div>
              `,
          };
        })
      )
      .subscribe({ error: err => console.error('Error loading chart data:', err) });
  }

  protected onStateWiseShipmentPerformanceTabChange(tabId: string): void {
    this.dashboardStore.updateStatePerformanceDefaultTab(tabId);
    this.dashboardStore.updateStatePerformacePagination(tabId, 1);
  }

  protected onStateWiseShipmentPerformancePageChange(event: {
    tabId: string;
    page: number;
    limit: number;
  }): void {
    this.dashboardStore.updateStatePerformacePagination(event.tabId, event.page, event.limit);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
