<div class="ce-row">
  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1">
    <div class="grid spacing-16">
      <app-analytics-widget
        class="w-100"
        title="Net Sales"
        subtitle="Track the net sales."
        metricsKey="net_sales"
        [analytics]="salesAnalytics$ | async"
        [compare]="comparePeriod"
        [context]="context"></app-analytics-widget>
    </div>
  </div>
  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1">
    <app-analytics-widget-host headingTitle="Orders Over Time">
      <app-chart-container
        [data]="ordersOverTimeChart?.data"
        [options]="ordersOverTimeChart?.options"></app-chart-container>
    </app-analytics-widget-host>
  </div>
</div>
