<div
  [style.height]="height ? height + 'px' : '350px'"
  [style.width]="width ? width + 'px' : 'auto'"
  [class.tab-height]="chartType === 'bar'">
  @if (chartType !== 'bar') {
    <canvas
      baseChart
      [width]="width"
      [height]="height"
      [datasets]="datasets"
      [data]="data"
      [labels]="labels"
      [options]="mergedOptions"
      [legend]="legend"
      [type]="type">
    </canvas>
  } @else if (chartType === 'bar') {
    <app-bar-chart-container
      [title]="title"
      [multipleTitles]="multipleTitles"
      [stats]="statsData"
      [statsMap]="statsMap"
      [pagination]="paginationData"
      [showPagination]="showPagination"
      [badgeType]="badgeType"
      [tabs]="tabs"
      [defaultActiveTab]="defaultActiveTab || (tabs && tabs.length > 0 ? tabs[0].id : 'state')"
      (tabChange)="onTabChange($event)"
      (pageChange)="onPageChange($event)"></app-bar-chart-container>
  } @else {
    <div class="h4-bold primary-100">No data available</div>
  }
</div>
