import { Injectable } from '@angular/core';
import {
  AnalyticsChartSettings,
  AnalyticsInterval,
  AnalyticsRangeContext,
  analyticsStatsTransformer,
  DefaultChartConfigService,
  extractDates,
  extractMetrics,
  getChartSettings,
} from '@services/analytics-services/core';
import { catchError, map, Observable, of } from 'rxjs';
import { DateRangePresetType } from '@services/core-services/models';
import { InsightsAnalyticsService } from '@services/analytics-services/insights/insights-analytics.service';
import { ChartConfiguration } from 'chart.js';

@Injectable()
export class InsightsAnalyticsCharts {
  constructor(
    private readonly chartConfigService: DefaultChartConfigService,
    private readonly insightsAnalyticsService: InsightsAnalyticsService
  ) {}

  getVisitorsOverTimeChart(context: AnalyticsRangeContext): Observable<AnalyticsChartSettings> {
    const { range, interval, preset } = context;
    return this.insightsAnalyticsService
      .getVisitorsOverTime({
        preset: preset as DateRangePresetType,
        range,
        interval,
      })
      .pipe(
        catchError(error => {
          console.error('Error fetching visitors over time:', error);
          return of({ list: [] });
        }),
        map(({ list }) => {
          const labels = extractDates(list, interval as AnalyticsInterval);

          const [visitor_count] = extractMetrics(list, ['visitor_count']);

          return getChartSettings('line', {
            labels,
            datasets: [{ data: visitor_count, label: 'visitors' }],
          }) as AnalyticsChartSettings;
        })
      );
  }

  getVisitorsByDeviceChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    const { range, interval, preset } = context;
    return this.insightsAnalyticsService
      .getVisitorsByDevice({
        preset: preset as DateRangePresetType,
        range,
        interval,
      })
      .pipe(
        map(({ list }) => {
          const transformer = analyticsStatsTransformer({
            device: 'Device',
            unique_users: 'Unique Users',
          });

          return this.chartConfigService.getChartConfig('doughnut', list, transformer, {
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
            plugins: {
              legend: {
                position: 'right',
                align: 'center',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'circle',
                  boxWidth: 8,
                  boxHeight: 8,
                  padding: 15,
                  font: {
                    size: 12,
                  },
                },
              },
            },
          });
        })
      );
  }

  getBounceRateChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    const { range, interval, preset } = context;
    return this.insightsAnalyticsService
      .getBounceRate({
        preset: preset as DateRangePresetType,
        range,
        interval,
      })
      .pipe(
        map(({ list }) => {
          const transformer = analyticsStatsTransformer({
            user_count: 'User Count',
            bounced_user_count: 'Bounced User Count',
          });

          return this.chartConfigService.getChartConfig('pie', list, transformer, {
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
          });
        })
      );
  }

  getSessionStatChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    return this.insightsAnalyticsService.getSessionsStat(context).pipe(
      map(data => {
        const transformer = analyticsStatsTransformer({
          origin_source: 'Source',
          session_count: 'Count',
        });

        return this.chartConfigService.getChartConfig('bar', data, transformer, {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        });
      })
    );
  }

  getSessionEventStatChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    return this.insightsAnalyticsService.getPageStat(context).pipe(
      map(data => {
        const transformer = analyticsStatsTransformer({
          event_name: 'Event Name',
          events_count: 'Event Count',
        });

        return this.chartConfigService.getChartConfig('bar', data, transformer, {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        });
      })
    );
  }

  getServerEventsStatChart(context: AnalyticsRangeContext): Observable<any> {
    return this.insightsAnalyticsService.getServerEventsStat(context).pipe(
      map(data => {
        const transformer = analyticsStatsTransformer({
          event_name: 'Server Event',
          events_count: 'Event Count',
        });
        return transformer(data); // Apply the transformer and return the result
      })
    );
  }
}
