import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { formatDate, formatHour } from '@services/core-services/utils/date.utils';
import { AnalyticsRangeContext } from '@services/analytics-services/core';

// Use import() with type assertion instead of @ts-ignore
import('highcharts/modules/heatmap').then(module => {
  module.default(Highcharts);
});

// Update day names to match what's returned from the API
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
// Shortened versions for display
const daysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

@Component({
  selector: 'app-traffic-matrix',
  standalone: true,
  imports: [],
  template: '<div #heatmapContainer style="width: 100%; height: 340px;"></div>',
  styles: [':host { display: block; }'],
})
export class TrafficMatrixComponent implements OnChanges {
  @ViewChild('heatmapContainer', { static: true }) heatmapContainer!: ElementRef;

  @Input() data: any[] = [];
  @Input() context!: AnalyticsRangeContext;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']?.currentValue) {
      this.renderChart(changes['data']?.currentValue);
    }
  }

  renderChart(list: any[]) {
    if (!list || list.length === 0) {
      // Handle empty data state
      this.renderEmptyChart();
      return;
    }

    const data = this.transformToHeatmapMatrix(list);
    const context = this.context;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // Calculate min and max values from the data
    const values = list.map(item => item.unique_user_count || 0);
    const maxValue = Math.max(...values);

    const options: Highcharts.Options = {
      chart: {
        type: 'heatmap',
        backgroundColor: 'transparent',
      },
      title: {
        text: undefined,
      },
      xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        categories: daysShort,
        gridLineColor: '#00FF00',
        minorGridLineColor: '#00FF00',
      },
      yAxis: {
        categories: this.getYAxisCategories(),
        title: {
          text: undefined,
        },
        gridLineColor: '#00FF00',
        minorGridLineColor: '#00FF00',
        gridLineWidth: 0,
        lineWidth: 0,
      },

      legend: {
        enabled: false,
      },
      colorAxis: {
        min: 0,
        minColor: 'transparent',
        maxColor: '#679298',
        max: maxValue,
        stops: [
          [0, 'transparent'],
          [0.01, '#f6f6f6'],
          [0.5, '#dfdfdf'],
          [1, '#679298'],
        ],
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: function (): string {
          // Cast this to any to access heatmap-specific properties
          const tooltipContext = this as any;
          const hour = tooltipContext.point?.y || 0;
          const nextHour = hour + 1;
          const dayIndex = tooltipContext.point?.x || 0;
          const day = daysOfWeek[dayIndex];
          const dayShort = daysShort[dayIndex];
          const value = tooltipContext.point?.value || 0;

          // Check if there is actual data
          if (value === 0) {
            return `<div class="custom-chart-tooltip" style="padding:12px;">
              <div class="d-flex flex-column spacing-8 align-items-start w-100">
                <h4 class="h4-bold primary-0 text-capitalize" style="margin: 0; color: white;">${dayShort}, ${formatHour(hour)} - ${formatHour(nextHour)}</h4>
                <span class="dash-boarder w-100"></span>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                  <div class="h4-reg primary-50">No visitors during this time</div>
                </div>
              </div>
            </div>`;
          }

          return `
            <div class="custom-chart-tooltip" style="padding:12px;">
              <div class="d-flex flex-column spacing-8 align-items-start w-100">
                <h4 class="h4-bold primary-0 text-capitalize" style="margin: 0; color: white;">${dayShort}, ${formatHour(hour)} - ${formatHour(nextHour)}</h4>
                <span class="dash-boarder w-100"></span>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                  <div class="d-flex align-items-center spacing-4 w-100 text-center">
                    <div style="background:${tooltipContext.point?.color || tooltipContext.series.color}" class="tooltipDot d-flex align-items-center h4-bold">&nbsp;</div>
                    <div class="h4-reg primary-50">Visitors</div>
                  </div>
                  <div class="h4-bold primary-0">${value}</div>
                </div>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                  <div class="d-flex align-items-center spacing-4 w-100 text-center">
                    <div class="h4-reg primary-50">Share of ${dayShort}'s Traffic</div>
                  </div>
                  <div class="h4-bold primary-0">${self.getPercentageOfDay(list, day, hour)}%</div>
                </div>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                  <div class="d-flex align-items-center spacing-4 w-100 text-center">
                    <div class="h4-reg primary-50">Share of Total Weekly Traffic</div>
                  </div>
                  <div class="h4-bold primary-0">${self.getPercentageOfAllOthers(list, day, hour)}%</div>
                </div>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                  <div class="d-flex align-items-center spacing-4 w-100 text-center">
                    <div class="h4-reg primary-50">Date Range</div>
                  </div>
                  <div class="h4-bold primary-0">${formatDate(context.range.start)} - ${formatDate(context.range.end)}</div>
                </div>
              </div>
            </div>
          `;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        heatmap: {
          dataLabels: {
            enabled: false,
            color: '#000000',
            formatter: function (): string | undefined {
              // Cast this.point to any to access heatmap-specific properties
              const point = (this as any).point;
              // Only show value if it's greater than 0
              return point.value > 0 ? `${point.value}` : undefined;
            },
          },
          borderWidth: 1,
          borderColor: 'var(--primary-0)',
          borderRadius: 2,
        },
      },
      series: [
        {
          type: 'heatmap',
          name: 'Website Metrics',
          data: data,
        } as Highcharts.SeriesHeatmapOptions,
      ],
    };

    Highcharts.chart(this.heatmapContainer.nativeElement, options);
  }

  private renderEmptyChart() {
    const options: Highcharts.Options = {
      chart: {
        type: 'heatmap',
        backgroundColor: 'transparent',
      },
      title: {
        text: 'No traffic data available for the selected period',
        style: {
          fontSize: '14px',
          color: '#666',
        },
      },
      xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        categories: daysShort,
      },
      yAxis: {
        categories: this.getYAxisCategories(),
        title: {
          text: undefined,
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'heatmap',
          name: 'Website Metrics',
          data: [],
        } as Highcharts.SeriesHeatmapOptions,
      ],
    };

    Highcharts.chart(this.heatmapContainer.nativeElement, options);
  }

  private transformToHeatmapMatrix(list: any[]): number[][] {
    const matrix: number[][] = [];

    // Create a lookup map for faster access
    const dataMap = new Map();

    // Normalize the day names and build the map
    list.forEach(item => {
      const day = item.day ? item.day.trim() : '';
      const hour = parseInt(item.hour, 10);
      const visitors = item.unique_user_count || 0;

      // Store data with normalized day name as key
      dataMap.set(`${day}-${hour}`, visitors);
    });

    // Generate the matrix data points
    daysOfWeek.forEach((day, dayIndex) => {
      for (let hour = 0; hour < 24; hour++) {
        // Look up the value in our map, default to 0 if not found
        const value = dataMap.get(`${day}-${hour}`) || 0;
        matrix.push([dayIndex, hour, value]);
      }
    });

    return matrix;
  }

  private getYAxisCategories() {
    return Array.from({ length: 24 }, (_, i) => i.toString());
  }

  private getPercentageOfDay(data: any[], targetDay: string, targetHour: number): string {
    const dayItems = data.filter(item => {
      const day = item.day ? item.day.trim() : '';
      return day === targetDay;
    });

    const targetItem = dayItems.find(item => parseInt(item.hour, 10) === targetHour);
    if (!targetItem) return '0.00';

    const dayTotal = dayItems.reduce((sum, item) => sum + (item.unique_user_count || 0), 0);
    return dayTotal > 0 ? ((targetItem.unique_user_count / dayTotal) * 100).toFixed(2) : '0.00';
  }

  private getPercentageOfAllOthers(data: any[], targetDay: string, targetHour: number): string {
    const targetItem = data.find(item => {
      const day = item.day ? item.day.trim() : '';
      return day === targetDay && parseInt(item.hour, 10) === targetHour;
    });

    if (!targetItem) return '0.00';

    const totalVisitors = data.reduce((sum, item) => sum + (item.unique_user_count || 0), 0);
    if (totalVisitors === 0) return '0.00';

    return ((targetItem.unique_user_count / totalVisitors) * 100).toFixed(2);
  }
}
