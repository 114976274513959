import { Orders, OrderStatus } from '@services/order-services/models';
import { ViewStatus } from '@services/ui-services/models/view-status.enum';
import { PaymentStatus } from '@services/payment-services/models/payment.status.enum';
import { DashboardorderList } from './dashboard-order-table-header.constant';

type OrderTableRow = Orders & {
  isSelected?: boolean;
};

const getOrderStatus = (order: OrderTableRow) => {
  let statusType = '';

  if (order.status === OrderStatus.Cancelled) {
    statusType = ViewStatus.Cancelled;
  } else if (
    order.status === OrderStatus.AwaitingApproval ||
    order.status === OrderStatus.Pending ||
    order.status === OrderStatus.PartiallyDelivered ||
    order.status === OrderStatus.PartiallyScheduled ||
    order.status === OrderStatus.PartiallyShipped ||
    order.status === OrderStatus.PartiallyUndelivered
  ) {
    statusType = ViewStatus.Paused;
  } else if (order.status === OrderStatus.PaymentFailed) {
    statusType = ViewStatus.Cancelled;
  } else if (order.status === OrderStatus.AwaitingShipment) {
    statusType = ViewStatus.Live;
  } else if (order.status === OrderStatus.PartiallyReturnToOrigin) {
    statusType = ViewStatus.Draft;
  } else if (
    order.status === OrderStatus.Confirmed ||
    order.status === OrderStatus.Shipped ||
    order.status === OrderStatus.Completed
  ) {
    statusType = ViewStatus.Completed;
  }

  return {
    label: order.status,
    Type: statusType,
  };
};

const getPaymentStatus = (order: OrderTableRow) => {
  let paymentStatusType = '';

  if (order.payment_status === PaymentStatus.Cancelled) {
    paymentStatusType = ViewStatus.Cancelled;
  } else if (order.payment_status === PaymentStatus.Unsettled) {
    paymentStatusType = ViewStatus.Cancelled;
  } else if (order.payment_status === PaymentStatus.Settled) {
    paymentStatusType = ViewStatus.Completed;
  } else if (order.payment_status === PaymentStatus.PartiallySettled) {
    paymentStatusType = ViewStatus.Paused;
  } else if (order.payment_status === PaymentStatus.Refunded) {
    paymentStatusType = ViewStatus.Draft;
  } else if (order.payment_status === PaymentStatus.Pending) {
    paymentStatusType = ViewStatus.Paused;
  } else if (order.payment_status === PaymentStatus.Success) {
    paymentStatusType = ViewStatus.Completed;
  }

  return {
    label: order.payment_status,
    Type: paymentStatusType,
  };
};

export const mapDashboardOrderTableData = (order: OrderTableRow) => {
  let index = 0;
  return [
    {
      column: DashboardorderList[index++].column,
      type: 'orders',
      orders: {
        number: order.order_number,
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'customer',
      label: 'Name',
      customer: {
        name: order?.billing_address.first_name + ' ' + order.billing_address.last_name,
        customerid: order.customer_id,
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'text',
      text: {
        title: order.billing_address.city,
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'text',
      text: {
        title: '₹' + order.grand_total,
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'text',
      text: {
        title: order.order_items_count + ' Items',
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'status',
      status: getOrderStatus(order),
    },
    {
      column: DashboardorderList[index++].column,
      type: 'status',
      status: getPaymentStatus(order),
    },
    {
      column: DashboardorderList[index++].column,
      type: 'text',
      text: {
        title: order?.loyalty_point_earned,
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'date',
      date: {
        title: order.order_date,
        format: 'dd MMM, yyyy, hh:mm a',
      },
    },
    {
      column: DashboardorderList[index++].column,
      type: 'actions',
      label: '',
      actions: [
        {
          icon: 'chevron_right',
          action: 'customer-order-detail',
          text: '',
          active: false,
          orderId: order.order_number,
        },
      ],
    },
  ];
};
