import * as Highcharts from 'highcharts';
import { ChartOptions } from './high-chart-config';

export class BarChartConfig {
  static getBarChartOptions(options: ChartOptions): Highcharts.Options {
    const dataLength = options?.data?.length || 0;
    let pointWidth;
    if (dataLength < 8) {
      pointWidth = undefined;
    } else {
      pointWidth = 30;
    }
    return {
      chart: {
        type: 'bar',
      },
      title: {
        text: null as any,
      },
      xAxis: {
        categories: options?.categories || [],
        allowDecimals: true,
        title: {
          text: null,
        },
        lineWidth: 0,
        labels: {
          enabled: true,
          formatter: function () {
            const parts = String(this.value).split('_');
            const datePart = parts.pop();
            const formattedLabel = parts.join(' ') + ' ' + datePart;
            return formattedLabel;
          },
          style: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 100,
          },
        },
        gridLineWidth: 0,
        visible: true,
      },
      yAxis: {
        min: 0,
        allowDecimals: true,
        title: {
          text: options?.yAxisTitle || '',
          align: 'high',
        },
        gridLineWidth: 0,
        visible: false,
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          if (options?.tooltipFormatter) {
            return options.tooltipFormatter(this);
          }
          return `
             <b>${this.key}</b><br/>
            ${this.series.name}: <b>${this.y}</b>
          `;
        },
      },
      plotOptions: {
        bar: {
          pointWidth: pointWidth,
          pointPadding: 0.1,
          groupZPadding: 2,
          states: {
            hover: {
              color: '#b3b3b3',
            },
          },
        },
        series: {
          dataLabels: [
            {
              enabled: true,
            },
            {
              enabled: false,
              align: 'left',
              inside: false,
              formatter: function () {
                return this.category;
              },
              style: {
                fontSize: '13px',
                fontWeight: 'normal',
                textOutline: 'none',
              },
            },
          ],
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          allowDecimals: true,
          showInLegend: false,
          name: 'Values',

          data: (options?.data || []).map(item => ({
            y: item.y,
            label: item.label ?? 'null',
          })),
          color: '#ececec',
        } as any,
      ],
      lang: {
        noData: 'No data to display',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
    };
  }
}
