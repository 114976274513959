import { TopCustomerList } from './dashboard-customer-table-header.constant';

export const mapDashboardCustomerTableData = (customer: any) => {
  let index = 0;
  return [
    {
      column: TopCustomerList[index++].column,
      type: 'customer',
      label: 'Name',
      customer: {
        name: customer?.name,
      },
    },
    {
      column: TopCustomerList[index++].column,
      type: 'text',
      text: {
        title: customer.order_count,
      },
    },
    {
      column: TopCustomerList[index++].column,
      type: 'text',
      text: {
        title: '₹' + customer.total_order_value,
      },
    },
    {
      column: TopCustomerList[index++].column,
      type: 'text',
      text: {
        title: '₹' + customer.avg_order_value,
      },
    },

    {
      column: TopCustomerList[index++].column,
      type: 'actions',
      label: '',
      actions: [
        {
          icon: 'chevron_right',
          action: 'customer-detail',
          text: '',
          active: false,
          customerId: customer.user_id,
        },
      ],
    },
  ];
};
