import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnalyticsRangeContext } from '@services/analytics-services/core';
import { AsyncPipe } from '@angular/common';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';
import { DashboardStore } from '../dashboard/dashboard.store';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { TableView } from '@services/ui-services/models';
import { TopCustomerList } from '@services/analytics-services/customers/dashboard-customer-table-header.constant';
import { ProcessingState } from '@services/core-services/models';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { mapDashboardCustomerTableData } from '@services/analytics-services/customers/dashboard-customer.table.mapper';
import { SubsequentPurchasesList } from '@services/analytics-services/customers/dashboard-subsequent-purchase-table-header.constant';
import { mapSubsequentPurchaseTableData } from '@services/analytics-services/customers/dashboard-subsequent-purchase-table.mapper';

@Component({
  selector: 'app-dashboard-customers',
  imports: [AsyncPipe, AnalyticsTrendWidgetComponent, TableViewComponent],
  templateUrl: './dashboard-customers.component.html',
  styleUrl: './dashboard-customers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCustomersComponent {
  protected customerAnalytics$;
  public customerLoader$ = new Observable<ProcessingState>();
  public customerErrorMessage$: Observable<string>;
  public subsequentPurchasesLoader$ = new Observable<ProcessingState>();
  public subsequentPurchasesErrorMessage$: Observable<string>;
  protected context: AnalyticsRangeContext | undefined;
  protected customerTable: TableView = {
    head: TopCustomerList,
    rows: [],
    data: [],
  };

  protected subsequentPurchasesTable: TableView = {
    head: SubsequentPurchasesList,
    rows: [],
    data: [],
  };

  constructor(
    private readonly dashboardStore: DashboardStore,
    private router: Router
  ) {
    this.dashboardStore.getRangeContext().subscribe(context => this.fetchAnalytics(context));
    this.customerAnalytics$ = this.dashboardStore.getCustomerAnalytics();

    this.customerErrorMessage$ = this.dashboardStore.getErrorMessage();
    this.customerLoader$ = this.dashboardStore.getTopCustomerLoader();

    this.subsequentPurchasesErrorMessage$ =
      this.dashboardStore.getsubsequentPurchasesErrorMessage();
    this.subsequentPurchasesLoader$ = this.dashboardStore.getsubsequentPurchasesLoader();

    this.dashboardStore.getTopCustomers().subscribe(customer => {
      this.customerTable.rows = customer?.map((customer: any) =>
        mapDashboardCustomerTableData(customer)
      );
    });

    this.dashboardStore.getSubsequentPurchases().subscribe(customer => {
      this.subsequentPurchasesTable.rows = customer?.map((customer: any) =>
        mapSubsequentPurchaseTableData(customer)
      );
    });
  }

  private fetchAnalytics(context: AnalyticsRangeContext) {
    this.context = context;
    this.dashboardStore.loadCustomerAnalytics().subscribe();
    this.dashboardStore.loadTopCustomer().subscribe();
    this.dashboardStore.loadSubsequentPurchases().subscribe();
  }

  onActionClick(entry: any) {
    if (entry.action == 'customer-detail') {
      this.router.navigateByUrl(`customers/${entry.customerId}/overview`);
    }
    if (entry.action == 'user-detail') {
      this.router.navigateByUrl(`customers/${entry.customerId}/overview`);
    }
  }
}
