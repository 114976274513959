import { Injectable } from '@angular/core';
import {
  AnalyticsRangeContext,
  analyticsStatsTransformer,
  DefaultChartConfigService,
} from '@services/analytics-services/core';
import { map, Observable, tap } from 'rxjs';
import { ChartConfiguration } from 'chart.js';
import { ShippingAnalyticsService } from '@services/analytics-services/shipping/shipping-analytics.service';
import { summarize } from '@services/analytics-services/core/analytics.helper';

@Injectable()
export class ShippingAnalyticsCharts {
  constructor(
    private readonly chartConfigService: DefaultChartConfigService,
    private readonly shippingAnalyticsService: ShippingAnalyticsService
  ) {}

  getShippingSLAStatsChart(context: AnalyticsRangeContext): Observable<any> {
    return this.shippingAnalyticsService.getShippingSLAStats(context).pipe(
      map(({ list }) => {
        const transformer = analyticsStatsTransformer({
          slo_status: 'Status',
          shipment_count: 'Count',
        });

        return this.chartConfigService.getChartConfig('pie', list, transformer, {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        });
      })
    );
  }
  //TODO: will remove later-
  getShippingByCourierChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    return this.shippingAnalyticsService.getShippingByCourier(context).pipe(
      tap(({ list }) => list.sort((a: any, b: any) => b.avg_delivery_time - a.avg_delivery_time)),
      map(({ list }) => {
        return summarize(list, {
          labelKey: 'courier',
          valueKey: 'avg_delivery_time',
          limit: 10,
        });
      }),
      map(list => {
        const transformer = analyticsStatsTransformer({
          courier: 'Courier',
          orders_shipped: 'Orders Shipped',
        });

        return this.chartConfigService.getChartConfig('bar', list, transformer, {
          indexAxis: 'y',
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        });
      })
    );
  }

  //changed for bar chart//
  getShippingByCourierCharts(context: AnalyticsRangeContext): Observable<any[]> {
    return this.shippingAnalyticsService.getOrderShippedByCourier(context).pipe(
      tap(({ list }) => list.sort((a: any, b: any) => b.orders_shipped - a.orders_shipped)), // Sorting
      map(({ list }) =>
        summarize(list, {
          labelKey: 'courier',
          valueKey: 'orders_shipped',
          limit: 10, // Applying the limit
        })
      ),
      map(list =>
        list.map((item: any) => ({
          label: item.courier,
          value: item.orders_shipped,
        }))
      )
    );
  }

  getAverageDeliveryByCourierCharts(context: AnalyticsRangeContext): Observable<any[]> {
    return this.shippingAnalyticsService.getShippingByCourier(context).pipe(
      tap(({ list }) => list.sort((a: any, b: any) => b.avg_delivery_time - a.avg_delivery_time)), // Sorting
      map(({ list }) =>
        summarize(list, {
          labelKey: 'courier',
          valueKey: 'avg_delivery_time',
          limit: 10, // Applying the limit
        })
      ),
      map(list =>
        list.map((item: any) => ({
          label: item.courier,
          value: item.avg_delivery_time,
        }))
      )
    );
  }

  //TODO: will remove later-
  getAverageDeliveryByCourierChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    return this.shippingAnalyticsService.getShippingByCourier(context).pipe(
      tap(({ list }) => list.sort((a: any, b: any) => b.avg_delivery_time - a.avg_delivery_time)),
      map(({ list }) => {
        return summarize(list, {
          labelKey: 'courier',
          valueKey: 'avg_delivery_time',
          limit: 10,
        });
      }),
      map(list => {
        const transformer = analyticsStatsTransformer({
          courier: 'Courier',
          avg_delivery_time: 'Average delivery time',
        });

        return this.chartConfigService.getChartConfig('bar', list, transformer, {
          indexAxis: 'y',
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        });
      })
    );
  }
}
