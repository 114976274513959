<div class="ce-row">
  <div class="ce-col-lg-3 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <div class="grid">
      <div class="d-flex flex-column">
        <h3 class="h4-reg primary-60"><span class="pulse-dot"></span> Real Time</h3>
        <div class="h2-subheaders primary-100">{{ activeUsers }} online</div>
      </div>
    </div>
  </div>

  <div class="ce-col-lg-3 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Visitors"
      metricsKey="unique_users"
      [context]="context"
      [analytics]="visitorAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-3 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Page views"
      metricsKey="total_views"
      [context]="context"
      [analytics]="viewAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-3 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Events"
      metricsKey="total_events"
      [context]="context"
      [analytics]="eventAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1 h-full">
    <app-analytics-widget-host
      headingTitle="Traffic"
      subtitle="Understand which days and hours of the day are most popular">
      <app-traffic-matrix [context]="context" [data]="trafficMatrixDatas"> </app-traffic-matrix>
    </app-analytics-widget-host>
  </div>

  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1 h-full">
    <div class="grid">
      <app-analytics-widget
        class="w-100"
        title="Visitors"
        subtitle="Number of visitors in the selected period"
        metricsKey="unique_users"
        [height]="260"
        [compare]="comparePeriod"
        [context]="context"
        [analytics]="visitorAnalytics$ | async"></app-analytics-widget>
    </div>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1 h-full">
    <app-analytics-widget-host
      headingTitle="Visitors by source"
      subtitle="Understand which sources and campaigns are driving traffic">
      <!-- <ce-form-field actions>
        <mat-select ceInput value="origin_source">
          <mat-option value="origin_source">Source</mat-option>
          <mat-option value="origin_country">Country</mat-option>
        </mat-select>
      </ce-form-field>-->
      <!-- <app-high-chart-container [chartOptions]="sessionChartOptions"></app-high-chart-container> -->
      <app-chart-container
        class="chart-container"
        [height]="'auto'"
        [width]="'auto'"
        [legend]="false"
        [chartType]="'bar'"
        [DataLabel]="'Source'"
        [title]="'Visitors'"
        [multipleTitles]="visitorMetricTitles"
        [statsMap]="groupedVisitors"
        [tabs]="visitorsTabs"
        [defaultActiveTab]="defaultActiveTab"
        [showPagination]="true"
        [paginationData]="visitorsPagination"
        (tabChange)="onVisitorsTabChange($event)"
        (pageChange)="onVisitorsPageChange($event)"
        [badgeType]="'white'">
      </app-chart-container>
    </app-analytics-widget-host>
  </div>

  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1">
    <app-analytics-widget-host
      headingTitle="Popular pages"
      subtitle="Pages with the highest number of visits">
      <app-high-chart-container
        [chartOptions]="pageStatsData"
        [emptyStateMessage]="'No page views recorded for the selected period'">
      </app-high-chart-container>
    </app-analytics-widget-host>
  </div>
</div>

<div class="ce-row">
  <!-- <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1">
    <app-analytics-widget-host [headingTitle]="'Visitors Source'">
      <app-metric-chart [config]="bounceRateChart" title="Bounce rate"></app-metric-chart>
    </app-analytics-widget-host>
  </div> -->
  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1">
    <app-analytics-widget-host
      headingTitle="Visitors by device type"
      subtitle="Breakdown of visitors by device type">
      <app-high-chart-container
        [chartOptions]="visitorsByDevice"
        [chartType]="'pie'"
        [showLegend]="true"
        [donut]="true"
        [emptyStateMessage]="'No device data available for the selected period'">
      </app-high-chart-container>
    </app-analytics-widget-host>
  </div>

  <div class="ce-col-lg-6 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1">
    <app-analytics-widget-host
      headingTitle="Popular events"
      subtitle="Most popular events in the selected period">
      <app-high-chart-container
        [chartOptions]="serverEvents"
        [chartType]="'pie'"
        [showLegend]="true"
        [donut]="true"
        [emptyStateMessage]="'No server events recorded for the selected period'">
      </app-high-chart-container>
    </app-analytics-widget-host>
  </div>
</div>
