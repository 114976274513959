<div class="chart-container">
  <div class="tab-chart spacing-8">
    <div class="chart-header d-flex align-items-center justify-content-between mb-3">
      <div class="chart-tabs">
        <ul class="nav nav-tabs spacing-2">
          @for (tab of tabs; track tab.id) {
            <li class="nav-item">
              <a
                class="nav-link h4-reg primary-60 cursor-pointer"
                [class.active]="activeTab === tab.id"
                (click)="setActiveTab(tab.id)"
                (keydown.enter)="setActiveTab(tab.id)"
                tabindex="0"
                role="tab"
                [attr.aria-selected]="activeTab === tab.id">
                {{ tab.label }}
              </a>
            </li>
          }
        </ul>
      </div>
      <div class="chart-actions d-flex align-items-center spacing-8">
        @for (title of multipleTitles; track title) {
          <div class="metric-title text-end" style="min-width: 90px">
            <h4 class="h4-reg primary-60">{{ title }}</h4>
          </div>
        }
      </div>
    </div>

    <div class="page-stats-list d-flex align-items-start spacing-8 flex-column">
      @if (filteredStats.length === 0) {
        <div>No data available</div>
      }
      <div class="d-flex spacing-4 align-items-center"></div>
      @for (stat of filteredStats; track stat; let i = $index) {
        <div class="page-stat-item d-flex align-items-center position-relative w-100">
          <div class="bar-and-label flex-grow-1 d-flex align-items-center position-relative">
            <div
              class="bar-background"
              [ngStyle]="{
                width: maxValue > 0 && stat.value > 0 ? calculateBarWidth(stat.value) : '0%',
                backgroundColor: i % 2 === 0 ? '#F5F5F5' : '#ECECEC',
              }"></div>
            <span class="primary-100 h4-reg position-relative bar-label">
              {{ stat.label }}
            </span>
          </div>
          <div class="d-flex align-items-center metric-badges">
            @if (currentItemMetrics[i]) {
              @for (metric of currentItemMetrics[i]; track metric.title) {
                <div class="badge-container text-end">
                  <app-badges [Type]="badgeType" [label]="formatValue(metric.value)"> </app-badges>
                </div>
              }
            }
          </div>
        </div>
      }
    </div>
  </div>

  @if (showPagination && pagination.total_pages > 1) {
    <div class="pagination-controls d-flex justify-content-end align-items-center mt-3">
      <div class="pagination-buttons">
        @if (pagination.previous_page) {
          <button
            class="btn btn-sm pagination-btn"
            (click)="previousPage()"
            (keydown.enter)="previousPage()"
            tabindex="0"
            aria-label="Previous page">
            Previous
          </button>
        }
        @if (pagination.next_page) {
          <button
            class="btn btn-sm pagination-btn ms-2"
            [disabled]="!pagination.next_page"
            (click)="nextPage()"
            (keydown.enter)="nextPage()"
            tabindex="0"
            aria-label="Next page">
            Next
          </button>
        }
      </div>
    </div>
  }
</div>
