import { Injectable } from '@angular/core';
import {
  AnalyticsRangeContext,
  analyticsStatsTransformer,
  DefaultChartConfigService,
} from '@services/analytics-services/core';
import { map, Observable } from 'rxjs';
import { DateRangePresetType } from '@services/core-services/models';
import { ChartConfiguration } from 'chart.js';
import { OrderAnalyticsService } from '@services/analytics-services/orders/order-analytics.service';

@Injectable()
export class OrdersAnalyticsCharts {
  constructor(
    private readonly chartConfigService: DefaultChartConfigService,
    private readonly orderAnalyticsService: OrderAnalyticsService
  ) {}

  getCheckoutConversionChart(context: AnalyticsRangeContext): Observable<ChartConfiguration> {
    const { range, interval, preset } = context;
    return this.orderAnalyticsService
      .getCheckoutConversion({
        preset: preset as DateRangePresetType,
        range,
        interval,
      })
      .pipe(
        map(({ list }) => {
          const transformer = analyticsStatsTransformer({
            carts_count: 'Carts Count',
            purchase_carts_count: 'Purchase Carts Count',
          });

          return this.chartConfigService.getChartConfig('pie', list, transformer, {
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
          });
        })
      );
  }
}
