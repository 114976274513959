<div class="chart-host">
  <div class="d-flex justify-content-between">
    @if (headingTitle || subtitle) {
      <div class="d-flex flex-column">
        <h3 class="h3-bold primary-100">{{ headingTitle }}</h3>
        <h4 *ngIf="subtitle" class="h4-reg primary-60">{{ subtitle }}</h4>
      </div>
    }
    <div>
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
  <div class="flex-grow-1">
    <ng-content></ng-content>
  </div>
</div>
