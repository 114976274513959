import * as Highcharts from 'highcharts';
import { ChartOptions } from './high-chart-config';
import { DEFAULT_COLORS } from '../../../../base/services/analytics-services/hight-chart-configurations';

export class PieChartConfig {
  static getPieChartOptions(options: ChartOptions): Highcharts.Options {
    const dataPoints = (options?.data || []).map((item, index) => ({
      name: item.label ?? 'null',
      y: item.y,
      color: item.color || DEFAULT_COLORS[index % DEFAULT_COLORS.length],
    }));
    const isDonut = options.donut === true;
    const config: Highcharts.Options = {
      chart: {
        type: 'pie',
        options3d: {
          enabled: false,
          alpha: 45,
        },
      },
      title: {
        text: null as any,
        align: 'center',
        verticalAlign: 'middle',
        floating: true,
      },
      subtitle: {
        text: ((options: ChartOptions) => {
          const mainText = (options as any).donutOptions?.centerText?.text;
          const subText = (options as any).donutOptions?.centerText?.subtext;

          if (!mainText) return '';

          const mainStyle = `font-size: 24px; font-weight: bold; color: #333333;`;
          const subStyle = `font-size: 14px; color: #666666;`;

          let html = `<div style="${mainStyle}">${mainText}</div>`;
          if (subText) {
            html += `<div style="${subStyle}">${subText}</div>`;
          }
          return html;
        })(options),
        floating: true,
        verticalAlign: 'middle',
        y: -5,
        useHTML: true,
        style: {
          textAlign: 'center',
        },
      },
      colors: DEFAULT_COLORS,
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          innerSize: isDonut ? '60%' : 0,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%',
          },
          showInLegend: options.showLegend || false,
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          if (options?.tooltipFormatter) {
            return options.tooltipFormatter(this);
          }
          return `
            <b>${this.key}</b><br/>
            ${this.series.name}: <b>${this.y}</b>
          `;
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: options.showLegend || false,
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Values',
          colorByPoint: true,
          innerSize: isDonut ? '60%' : 0,
          data: dataPoints,
        } as Highcharts.SeriesPieOptions,
      ],
      lang: {
        noData: 'No data to display',
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },
    };

    return config;
  }
}
