import { Component, OnDestroy } from '@angular/core';
import {
  AnalyticsRangeContext,
  AnalyticsResponse,
  ComparePeriod,
  mapAnalyticsResponse,
} from '@services/analytics-services/core';
import { DashboardStore } from '../dashboard/dashboard.store';
import { AnalyticsWidgetHostComponent } from '@enginuity/analytics/organisms/analytics-widget-host/analytics-widget-host.component';
import { SalesAnalyticsCharts } from '@services/analytics-services/sales/sales-analytics.charts';
import { ChartContainerComponent } from '@enginuity/analytics/organisms/chart-container/chart-container.component';
import { map, Observable, of, Subject, tap } from 'rxjs';
import { ChartConfiguration } from 'chart.js';
import { AsyncPipe } from '@angular/common';
import { AnalyticsWidgetComponent } from '@enginuity/analytics/organisms/analytics-widget/analytics-widget.component';
import { SalesAnalyticsService } from '@services/analytics-services/sales/sales-analytics.service';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-sales',
  imports: [
    AnalyticsWidgetHostComponent,
    ChartContainerComponent,
    AnalyticsWidgetComponent,
    AsyncPipe,
  ],
  templateUrl: './dashboard-sales.component.html',
  styleUrl: './dashboard-sales.component.scss',
  providers: [SalesAnalyticsCharts],
})
export class DashboardSalesComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  protected context!: AnalyticsRangeContext;

  protected netGrossSalesChart: ChartConfiguration | undefined;
  protected ordersOverTimeChart: ChartConfiguration | undefined;
  protected salesAnalytics$: Observable<any> | undefined;
  protected comparePeriod!: ComparePeriod;

  constructor(
    private readonly dashboardStore: DashboardStore,
    private readonly salesAnalyticsCharts: SalesAnalyticsCharts,
    private readonly salesAnalyticsService: SalesAnalyticsService
  ) {
    this.dashboardStore
      .getComparePeriod()
      .pipe(takeUntil(this.destroy$))
      .subscribe(period => (this.comparePeriod = period));

    this.dashboardStore
      .getRangeContext()
      .pipe(takeUntil(this.destroy$))
      .subscribe(context => {
        this.context = context;
        this.fetchAnalytics();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fetchAnalytics() {
    this.salesAnalytics$ = this.salesAnalyticsService.getNetAndGrossSalesMetrics(this.context).pipe(
      map(({ list }) =>
        mapAnalyticsResponse<any>(
          { content: { stats: list } } as unknown as AnalyticsResponse<any>,
          {
            range: this.context.range,
            compare: this.context.compare,
            interval: this.context.interval,
          }
        )
      )
    );

    this.salesAnalyticsCharts
      .getNetAndGrossSalesMetricsChart(this.context)
      .pipe(
        tap(config => (this.netGrossSalesChart = config)),
        catchError(error => {
          console.error('Error fetching net/gross sales chart:', error);
          return of(undefined);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.salesAnalyticsCharts
      .getOrdersOverTimeChart(this.context)
      .pipe(
        tap(config => (this.ordersOverTimeChart = config)),
        catchError(error => {
          console.error('Error fetching orders over time chart:', error);
          return of(undefined);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
