<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Total customers"
      metricsKey="total_customers"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="New customers"
      metricsKey="new_customers"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="CLV"
      metricsKey="customer_lifetime_value"
      formatter="currency"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <div class="grid-heading d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column spacing-4">
          <div class="hh3-bold primary-100">
            <span class="h3-bold tab-icon">Top 25 customers</span>
          </div>
          <span class="h4-reg primary-60">
            List of top 25 customers during the selected period</span
          >
        </div>
      </div>
      <div class="table-component w-100 top-product custom-scroll">
        <app-table-view
          (onClick)="onActionClick($event)"
          [table_schema]="customerTable"
          [loader]="customerLoader$ | async"
          [errorMessage]="customerErrorMessage$ | async"></app-table-view>
      </div>
    </div>
  </div>
</div>
<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <div class="grid-heading d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column spacing-4">
          <div class="hh3-bold primary-100">
            <span class="h3-bold tab-icon">Average Time Between Subsequent Purchases</span>
          </div>
          <span class="h4-reg primary-60">Track how frequently customers place repeat orders</span>
        </div>
      </div>
      <div class="table-component w-100 top-product custom-scroll">
        <app-table-view
          (onClick)="onActionClick($event)"
          [table_schema]="subsequentPurchasesTable"
          [loader]="subsequentPurchasesLoader$ | async"
          [errorMessage]="subsequentPurchasesErrorMessage$ | async"></app-table-view>
      </div>
    </div>
  </div>
</div>
