<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <div class="grid-heading d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column spacing-4">
          <div class="hh3-bold primary-100">
            <span class="h3-bold tab-icon">Top products</span>
          </div>
          <span class="h4-reg primary-60"> Products sold in the selected period </span>
        </div>
      </div>
      <div class="table-component w-100 top-product custom-scroll">
        <app-table-view
          (onClick)="onActionClick($event)"
          [table_schema]="productTable"
          [loader]="loader$ | async"
          [errorMessage]="errorMessage$ | async"></app-table-view>
      </div>
    </div>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <div class="grid-heading d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column spacing-4">
          <div class="hh3-bold primary-100">
            <span class="h3-bold tab-icon">Product Performance</span>
          </div>
          <span class="h4-reg primary-60"
            >Overview of orders, customer metrics, and purchase frequency by product</span
          >
        </div>
      </div>
      <div class="table-component w-100 top-product custom-scroll">
        <app-table-view
          (onClick)="onActionClick($event)"
          [table_schema]="productPerformanceTable"
          [loader]="productPerformanceLoader$ | async"
          [errorMessage]="productPerformanceErrorMessage$ | async"></app-table-view>
      </div>
    </div>
  </div>
</div>
