import { Component } from '@angular/core';
import { ComparePeriod } from '@services/analytics-services/core';
import { TableView } from '@services/ui-services/models';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { TopProductList } from '@services/analytics-services/products/constants/top-product-table-header';
import { ProcessingState } from '@services/core-services/models';
import { Observable } from 'rxjs';
import { DashboardStore } from '../dashboard/dashboard.store';
import { AsyncPipe } from '@angular/common';
import { mapTopProductTableData } from '@services/analytics-services/products/constants/top-product-table.mapper';
import { Router } from '@angular/router';
import { productPerformanceList } from '@services/analytics-services/products/constants/product-performance-table-header';
import { mapProductPerformaceTableData } from '@services/analytics-services/products/constants/product-performance-table.mapper';

@Component({
  selector: 'app-dashboard-products',
  imports: [TableViewComponent, AsyncPipe],
  templateUrl: './dashboard-products.component.html',
  styleUrl: './dashboard-products.component.scss',
})
export class DashboardProductsComponent {
  protected comparePeriod!: ComparePeriod;
  public loader$ = new Observable<ProcessingState>();
  public errorMessage$: Observable<string>;
  public productPerformanceLoader$ = new Observable<ProcessingState>();
  public productPerformanceErrorMessage$: Observable<string>;
  protected productTable: TableView = {
    head: TopProductList,
    rows: [],
    data: [],
  };
  protected productPerformanceTable: TableView = {
    head: productPerformanceList,
    rows: [],
    data: [],
  };

  constructor(
    private readonly store: DashboardStore,
    private router: Router
  ) {
    this.store.getComparePeriod().subscribe(period => (this.comparePeriod = period));
    this.store.getRangeContext().subscribe(() => this.fetchAnalytics());
    this.errorMessage$ = this.store.getErrorMessage();
    this.loader$ = this.store.getLoader();
    this.productPerformanceErrorMessage$ = this.store.getErrorMessage();
    this.productPerformanceLoader$ = this.store.productLoader();

    this.store.getTopProducts().subscribe(products => {
      this.productTable.rows = products?.map(product => mapTopProductTableData(product));
    });
    this.store.getProductperformance().subscribe(products => {
      this.productPerformanceTable.rows = products?.map(product =>
        mapProductPerformaceTableData(product)
      );
    });
  }

  private fetchAnalytics(): void {
    this.store.loadProductPerformance().subscribe();
    this.store.loadTopProducts().subscribe();
  }
  onActionClick(entry: any) {
    if (entry.action == 'product-detail') {
      this.router.navigateByUrl(`products/${entry.productId}/overview`);
    }
  }
}
