import { TableViewHeader } from '@services/ui-services/models';
import { Orders } from '@services/order-services/models';

type mapOrderTableRow = Orders & {
  isSelected?: boolean;
  billing_address___first_name?: string;
};

type TableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapOrderTableRow;
};

export const DashboardorderList: TableHead[] = [
  {
    type: 'order',
    label: 'Order number',
    sortable: true,
    isShow: true,
    column: 'order_number',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Customer',
    sortable: true,
    isShow: true,
    column: 'billing_address___first_name',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Location',
    sortable: true,
    isShow: false,
    column: 'billing_address',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Order value',
    sortable: false,
    isShow: true,
    column: 'grand_total',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Items',
    sortable: false,
    isShow: true,
    column: 'order_items_count',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Order Status',
    sortable: true,
    isShow: true,
    column: 'status',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Payment Status',
    sortable: true,
    isShow: true,
    column: 'payment_status',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Loyalty',
    sortable: false,
    isShow: false,
    column: 'loyalty_point_earned',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Order date',
    sortable: false,
    isShow: true,
    column: 'order_date',
    sortOrder: 'default',
  },
  {
    type: 'actions',
    label: '',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
  },
];
