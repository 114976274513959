import { TableViewHeader } from '@services/ui-services/models';
import { SubsequentPurchasesStats } from './subsequest-purchases-stats';

type mapCustomerTableRow = SubsequentPurchasesStats & {
  isSelected?: boolean;
};

type TableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapCustomerTableRow;
};

export const SubsequentPurchasesList: TableHead[] = [
  {
    type: 'string',
    label: 'Customer',
    sortable: true,
    isShow: true,
    column: 'name',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Order count',
    sortable: true,
    isShow: true,
    column: 'order_count',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Days b/w orders',
    sortable: false,
    isShow: true,
    column: 'avg_days_between_orders',
    sortOrder: 'default',
  },
  {
    type: 'actions',
    label: '',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
  },
];
