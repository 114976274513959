import { TableViewHeader } from '@services/ui-services/models';
import { ProductPerformanceStats } from '../models/product-performance-stats';

type mapCustomerProductTableRow = ProductPerformanceStats & {
  isSelected?: boolean;
};

type TableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapCustomerProductTableRow;
};

export const productPerformanceList: TableHead[] = [
  {
    type: 'string',
    label: 'Name',
    sortable: true,
    isShow: true,
    column: 'sku_name',
    sortOrder: 'default',
  },
  {
    type: 'number',
    label: 'Total orders',
    sortable: true,
    isShow: true,
    column: 'total_orders',
    sortOrder: 'default',
  },
  {
    type: 'number',
    label: 'Unique customers',
    sortable: true,
    isShow: true,
    column: 'unique_customers',
    sortOrder: 'default',
  },
  {
    type: 'number',
    label: 'Repeat customers',
    sortable: true,
    isShow: true,
    column: 'repeat_customers',
    sortOrder: 'default',
  },
  {
    type: 'number',
    label: 'Repeat customers rate',
    sortable: true,
    isShow: true,
    column: 'repeat_customer_rate',
    sortOrder: 'default',
  },
  {
    type: 'number',
    label: 'Days b/w orders',
    sortable: true,
    isShow: true,
    column: 'avg_days_between_orders',
    sortOrder: 'default',
  },
  {
    type: 'actions',
    label: '',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
  },
];
