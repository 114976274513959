<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="To be shipped"
      metricsKey="to_be_shipped"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="In transit"
      metricsKey="in_transit"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="On hold"
      metricsKey="on_hold"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Delivered"
      metricsKey="delivered"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Late deliveries"
      metricsKey="late_delivered"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Returned to origin"
      metricsKey="returned_to_origin"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-widget-host
      headingTitle="Shipment delivery rate"
      subtitle="Breakdown of early, on-time, and late deliveries">
      @if (shippingSLAChartOptions) {
        <app-high-chart-container
          [chartOptions]="shippingSLAChartOptions"
          [chartType]="'pie'"
          [showLegend]="true"
          [emptyStateMessage]="'No data available for the selected period'">
        </app-high-chart-container>
      }
    </app-analytics-widget-host>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-widget-host
      headingTitle="Shipments by carrier"
      subtitle="Number of shipments handled by each carrier">
      @if (CourierChartOptions) {
        <app-high-chart-container
          [chartOptions]="CourierChartOptions"
          [chartType]="'bar'"
          [emptyStateMessage]="'No data available for the selected period'">
        </app-high-chart-container>
      }
    </app-analytics-widget-host>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-widget-host
      headingTitle="Average delivery time"
      subtitle="Average number of days to deliver a shipment per carrier">
      @if (AverageDeliveryOptions) {
        <app-high-chart-container
          [chartOptions]="AverageDeliveryOptions"
          [chartType]="'bar'"
          [emptyStateMessage]="'No data available for the selected period'">
        </app-high-chart-container>
      }
    </app-analytics-widget-host>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 flex-1 h-full">
    <app-analytics-widget-host
      headingTitle="Shipping performance"
      subtitle="Analyze shipping efficiency across locations and couriers">
      <app-chart-container
        class="chart-container"
        [height]="'auto'"
        [width]="'auto'"
        [legend]="false"
        [chartType]="'bar'"
        [DataLabel]="'Shipment'"
        [title]="'Value'"
        [multipleTitles]="stateWiseShipmentPerformanceTitles"
        [statsMap]="$any(stateWiseShipmentPerformanceStats$ | async)"
        [tabs]="$any(stateWiseShipmentPerformanceTabs$ | async)"
        [defaultActiveTab]="$any(stateWiseShipmentPerformanceTabsActiveTab$ | async)"
        [showPagination]="true"
        [paginationData]="$any(stateWiseShipmentPerformancePagination$ | async)"
        (tabChange)="onStateWiseShipmentPerformanceTabChange($event)"
        (pageChange)="onStateWiseShipmentPerformancePageChange($event)"
        [badgeType]="'white'">
      </app-chart-container>
    </app-analytics-widget-host>
  </div>
</div>
