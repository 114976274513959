import { ChartConfiguration, ChartDataset } from 'chart.js';
export const barChartConfig = {
  DEFAULT_COLORS: [
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
    '#ECECEC',
  ],
  customizeBarDatasets(datasets: ChartDataset[]): ChartDataset[] {
    return datasets.map(dataset => ({
      ...dataset,
      barThickness: 30,
      maxBarThickness: 30,
      borderWidth: 0,
      borderRadius: 6,
      backgroundColor: this.DEFAULT_COLORS,
      datalabels: {
        display: true,
        anchor: 'start',
        offset: 10,
        align: (context: any) => {
          const value = context.dataset.data[context.dataIndex] as number;
          const threshold = 0;
          return value > threshold ? 'end' : 'center';
        },
        font: {
          weight: 'bold',
          size: 12,
        },
        color: '#333',
        formatter: (value: number, context: any) => {
          const label = context.chart.data.labels[context.dataIndex];
          return label != null && label !== '' ? label : 'N/A';
        },
      },
    }));
  },

  getCustomChartOptions(): ChartConfiguration['options'] {
    return {
      responsive: true,
      maintainAspectRatio: true,
      indexAxis: 'y',
      scales: {
        x: {
          display: false,
          ticks: {
            display: false,
            padding: 15,
          },
        },
        y: {
          display: false,
          grid: {
            display: false,
            color: 'rgba(200, 200, 200, 0.3)',
          },
          title: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        // datalabels: {
        //   display: true,
        // },
        tooltip: {
          enabled: false,
          intersect: true,
          position: 'nearest',
          external: this.getCustomTooltip.bind(this), // Bind custom tooltip handler
        },
      },
    };
  },

  getCustomTooltip(context: { chart: any; tooltip: any }) {
    const { chart, tooltip } = context;
    const tooltipEl = this.getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    const dataPoint = tooltip.dataPoints[0];
    const label = dataPoint.label;
    const value = dataPoint.raw;
    // const datasetLabel = dataPoint.dataset.label || 'Data';

    const tooltipContainer = tooltipEl.querySelector('div');
    if (tooltipContainer) {
      tooltipContainer.innerHTML = `
        <div class="custom-chart-tooltip" style="padding: 12px;">
            <div class="d-flex flex-column spacing-8 align-items-start">
             <h4 class="h4-bold primary-0" style="margin: 0;">${dataPoint.dataset.label}</h4>
             <span class="dash-boarder w-100"></span>
               <div class="d-flex align-items-start spacing-8 w-100">
                <div class="tooltipBadge green d-flex align-items-center h4-bold">&nbsp;</div>
                <div class="d-flex flex-column w-100 text-center">
                  <div class="h4-reg primary-50">${label}</div>
                  <div class="h4-bold primary-0">${value}</div>
                </div>
              </div>
            </div>
          </div>
      `;
    } else {
      console.warn('Tooltip container not found');
    }

    // here we can set the position of tooltip
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.zIndex = '9999';
    tooltipEl.style.pointerEvents = 'none';
  },

  getOrCreateTooltip(chart: any): HTMLElement {
    let tooltipEl = chart.canvas.parentNode.querySelector('div.custom-tooltip') as HTMLElement;
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.classList.add('custom-tooltip');
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.innerHTML = '<div></div>';
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
  },
};
