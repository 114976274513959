<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="New customers"
      metricsKey="new_customers"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Orders"
      metricsKey="total_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Gross revenue"
      metricsKey="gross_revenue"
      formatter="currency"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>

<div class="ce-row">
  <!-- Total customers -->
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <app-analytics-widget
        class="w-100"
        title="Customers"
        subtitle="Customers who have signed up in the selected period"
        metricsKey="total_customers"
        [height]="260"
        [analytics]="customerAnalytics$ | async"
        [compare]="comparePeriod"
        [context]="context"></app-analytics-widget>
    </div>
  </div>

  <!-- Total units sold -->
  <div class="ce-col-lg-8 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <app-analytics-widget
        class="w-100"
        title="Units sold"
        subtitle="Total units sold in the selected period"
        metricsKey="purchased"
        [height]="260"
        [analytics]="productAnalytics$ | async"
        [compare]="comparePeriod"
        [context]="context"></app-analytics-widget>
    </div>
  </div>

  <!-- Total revenue, excluding taxes -->
  <div class="ce-col-lg-8 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <app-analytics-widget
        class="w-100"
        title="Net revenue"
        subtitle="Total revenue for your store, exlcuding taxes"
        metricsKey="net_revenue"
        formatter="currency"
        [height]="260"
        [analytics]="orderAnalytics$ | async"
        [compare]="comparePeriod"
        [context]="context"></app-analytics-widget>
    </div>
  </div>

  <!-- Total orders -->
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <app-analytics-widget
        class="w-100"
        title="Orders"
        subtitle="Confirmed orders in the selected period"
        metricsKey="total_order"
        [height]="260"
        [compare]="comparePeriod"
        [context]="context"
        [analytics]="orderAnalytics$ | async"></app-analytics-widget>
    </div>
  </div>
</div>
