<div class="high-chart-container">
  <highcharts-chart
    *ngIf="hasData"
    [Highcharts]="Highcharts"
    [options]="options"
    style="width: 100%; display: block">
  </highcharts-chart>

  <div *ngIf="!hasData" class="empty-state">
    <div class="empty-state-icon">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32 8C18.7 8 8 18.7 8 32C8 45.3 18.7 56 32 56C45.3 56 56 45.3 56 32C56 18.7 45.3 8 32 8ZM32 52C20.95 52 12 43.05 12 32C12 20.95 20.95 12 32 12C43.05 12 52 20.95 52 32C52 43.05 43.05 52 32 52Z"
          fill="#CCCCCC" />
        <path d="M38 20H26V36H38V20Z" fill="#EEEEEE" />
        <path d="M46 28H34V44H46V28Z" fill="#EEEEEE" />
        <path d="M30 28H18V44H30V28Z" fill="#EEEEEE" />
      </svg>
    </div>
    <div class="empty-state-message">{{ emptyStateMessage }}</div>
  </div>
</div>
