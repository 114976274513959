export const DEFAULT_COLORS = [
  '#71966D',
  '#C7F4C2',
  '#679298',
  '#F88838',
  '#FFF7CF',
  '#EAE3F3',
  '#B8E6F2',
  '#FFB6C1',
  '#A2D2FF',
  '#FFD700',
];

export const STATUS_COLORS: { [key: string]: string } = {
  on_time: '#C7F4C2',
  early: '#6d9671',
  late: '#F88838',
  delay: '#BE555B',
  pending: '#679298',
  processing: '#A2D2FF',
  completed: '#FFD700',
  cancelled: '#B8374A',
  neutral: '#EBE1AD',
};

export const DEVICE_COLORS: { [key: string]: string } = {
  desktop: '#679298',
  mobile: '#F88838',
  tablet: '#FFF7CF',
  smarttv: '#20B2AA',
  console: '#FF69B4',
  wearable: '#32CD32',
  embedded: '#DAA520',
  unknown: '#A9A9A9',
};

export const FINANCIAL_COLORS = ['#4CAF50', '#F44336', '#2196F3', '#FF9800', '#9C27B0'];

export const DEMOGRAPHIC_COLORS = [
  '#3498db',
  '#e74c3c',
  '#2ecc71',
  '#f39c12',
  '#9b59b6',
  '#1abc9c',
  '#d35400',
  '#c0392b',
];

export const SEQUENTIAL_COLORS = [
  '#f7fbff',
  '#deebf7',
  '#c6dbef',
  '#9ecae1',
  '#6baed6',
  '#4292c6',
  '#2171b5',
  '#08519c',
  '#08306b',
];

export function getBarChartColor(): string {
  return DEFAULT_COLORS[0];
}
