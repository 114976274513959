import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, OnInit } from '@angular/core';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import {
  GroupChartTabs,
  Pagination,
  ChartDataPoint,
} from '@services/analytics-services/core/group-chart.modals';
import { ChartMetricsService } from '@services/analytics-services/core/chart-metrics.service';
import { EnhancedChartDataPoint } from '@services/analytics-services/core/grouped-data-store.service';

interface MetricValue {
  title: string;
  value: any;
}

@Component({
  selector: 'app-bar-chart-container',
  standalone: true,
  imports: [CommonModule, BadgesComponent],
  templateUrl: './bar-chart-container.component.html',
  styleUrl: './bar-chart-container.component.scss',
})
export class BarChartContainerComponent implements OnChanges, OnInit {
  @Output() tabChange = new EventEmitter<string>();
  @Output() pageChange = new EventEmitter<{ tabId: string; page: number; limit: number }>();

  @Input() stats: ChartDataPoint[] = [];
  @Input() statsMap: { [tabId: string]: ChartDataPoint[] } = {};
  @Input() tooltipTitle: string = '';
  @Input() title: string = '';
  @Input() multipleTitles: string[] = [];
  @Input() badgeType: string = 'white';
  @Input() tabs: GroupChartTabs[] = [];
  @Input() defaultActiveTab: string = 'overview';
  @Input() pagination: Pagination = {
    limit: 10,
    next_page: null,
    total_pages: 1,
    previous_page: null,
    total_records: 0,
    current_page: 1,
  };
  @Input() showPagination: boolean = false;

  protected maxValue: number = 0;
  activeTab: string = 'overview';
  filteredStats: ChartDataPoint[] = [];
  protected Math = Math;
  currentItemMetrics: MetricValue[][] = [];
  private maxBarWidthPercentage: number = 80;

  constructor(private chartMetricsService: ChartMetricsService) {}

  ngOnInit(): void {
    this.initActiveTab();
  }

  ngOnChanges(): void {
    if (this.defaultActiveTab && this.defaultActiveTab !== this.activeTab) {
      this.initActiveTab();
    } else {
      this.updateStats();
    }
  }

  private initActiveTab(): void {
    if (this.defaultActiveTab) {
      this.activeTab = this.defaultActiveTab;
    } else if (this.tabs && this.tabs.length > 0) {
      this.activeTab = this.tabs[0].id;
    } else {
      if (this.statsMap && this.statsMap['state']) {
        this.activeTab = 'state';
      } else if (this.statsMap && this.statsMap['courier']) {
        this.activeTab = 'courier';
      } else {
        this.activeTab = 'state';
      }
    }

    this.updateStats();
  }

  updateStats(): void {
    let statsForTab = this.statsMap[this.activeTab];

    if (!statsForTab && this.activeTab !== 'state' && this.statsMap['state']) {
      statsForTab = this.statsMap['state'];
      this.activeTab = 'state';
    } else if (!statsForTab && this.activeTab !== 'courier' && this.statsMap['courier']) {
      statsForTab = this.statsMap['courier'];
      this.activeTab = 'courier';
    } else if (!statsForTab) {
      statsForTab = this.stats;
    }
    this.filteredStats = Array.isArray(statsForTab)
      ? statsForTab.filter(
          (stat): stat is ChartDataPoint =>
            stat &&
            typeof stat.label === 'string' &&
            typeof stat.value === 'number' &&
            !isNaN(stat.value)
        )
      : [];
    this.maxValue =
      this.filteredStats.length > 0
        ? Math.max(...this.filteredStats.map(stat => stat.value), 0)
        : 0;

    this.prepareItemMetrics();
  }

  prepareItemMetrics(): void {
    this.currentItemMetrics = [];

    if (this.filteredStats.length === 0) return;

    if (this.multipleTitles.length === 0 && this.filteredStats.length > 0) {
      const firstItem = this.filteredStats[0] as EnhancedChartDataPoint;

      if (firstItem.metrics && firstItem.metrics.length > 0) {
        this.multipleTitles = firstItem.metrics.map(
          (metric: { title: string; value: any }) => metric.title
        );
      } else {
        const originalData = firstItem['originalData'] || firstItem;
        const metricSetName = this.chartMetricsService.identifyMetricSet(originalData);

        if (metricSetName) {
          const metricSetDefinition =
            this.chartMetricsService.getMetricSetDefinition(metricSetName);
          if (metricSetDefinition) {
            this.multipleTitles = metricSetDefinition.metrics.map(metric => metric.label);
          }
        }
      }
    }

    for (const stat of this.filteredStats) {
      const enhancedStat = stat as EnhancedChartDataPoint;

      if (enhancedStat.metrics && enhancedStat.metrics.length > 0) {
        this.currentItemMetrics.push(enhancedStat.metrics);
      } else {
        const originalData = stat['originalData'] || stat;

        const metricSetName =
          enhancedStat.metricSet || this.chartMetricsService.identifyMetricSet(originalData);

        if (metricSetName) {
          const metrics = this.chartMetricsService.extractMetrics(originalData, metricSetName);
          this.currentItemMetrics.push(metrics);
        } else {
          const metrics = this.multipleTitles.map(title => {
            const metricKey = this.chartMetricsService.getMetricKeyFromTitle(title);
            const value = originalData[metricKey] !== undefined ? originalData[metricKey] : 0;
            return { title, value };
          });

          this.currentItemMetrics.push(metrics);
        }
      }
    }
  }

  calculateBarWidth(value: number): string {
    if (this.maxValue <= 0 || value <= 0) return '0%';

    const percentage = (value / this.maxValue) * this.maxBarWidthPercentage;

    const metricCount = this.multipleTitles.length;
    const adjustedPercentage = Math.min(
      percentage,
      this.maxBarWidthPercentage - (metricCount > 3 ? 5 : 0)
    );

    return `${adjustedPercentage}%`;
  }

  formatValue(value: any): string {
    if (value === undefined || value === null) return '0';

    if (typeof value === 'number') {
      if (isNaN(value)) return '0';

      if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'k';
      }
      if (value < 1000 && value % 1 !== 0) {
        // Has decimal places
        return value.toFixed(1);
      }

      return value.toString();
    }

    return value.toString();
  }

  setActiveTab(tabId: string): void {
    if (this.activeTab !== tabId) {
      this.activeTab = tabId;
      this.updateStats();
      this.tabChange.emit(tabId);
    }
  }

  previousPage(): void {
    if (this.pagination.previous_page) {
      this.pageChange.emit({
        tabId: this.activeTab,
        page: this.pagination.previous_page,
        limit: this.pagination.limit,
      });
    }
  }

  nextPage(): void {
    if (this.pagination.next_page) {
      this.pageChange.emit({
        tabId: this.activeTab,
        page: this.pagination.next_page,
        limit: this.pagination.limit,
      });
    }
  }
}
