<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Live orders"
      metricsKey="live_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Orders"
      metricsKey="total_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Completed orders"
      metricsKey="completed_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <div class="grid-heading d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column spacing-4">
          <div class="hh3-bold primary-100">
            <span class="h3-bold tab-icon">Associated Campaigns</span>
          </div>
          <span class="h4-reg primary-60"
            >Analyze the marketing sources driving user engagement and conversions</span
          >
        </div>
      </div>
      <app-chart-container
        class="chart-container w-100"
        [height]="'auto'"
        [width]="'auto'"
        [legend]="false"
        [chartType]="'bar'"
        [DataLabel]="'Marketing Source'"
        [title]="'User Count'"
        [multipleTitles]="associatedCampaignsTitles"
        [statsMap]="$any(associatedCampaignsStats$ | async)"
        [tabs]="$any(associatedCampaignsTabs$ | async)"
        [defaultActiveTab]="$any(associatedCampaignsTabsActiveTab$ | async)"
        [showPagination]="true"
        [paginationData]="$any(associatedCampaignsPagination$ | async)"
        (tabChange)="onAssociatedCampaignsTabChange($event)"
        (pageChange)="onAssociatedCampaignsPageChange($event)"
        [badgeType]="'white'">
      </app-chart-container>
    </div>
  </div>
</div>

<div class="ce-row">
  <div class="ce-col-lg-12 ce-col-md-12 ce-col-sm-12 ce-col-12 order-history">
    <div class="grid spacing-16">
      <div class="grid-heading d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column spacing-4">
          <div class="hh3-bold primary-100">
            <span class="h3-bold tab-icon">Orders</span>
          </div>
          <span class="h4-reg primary-60">Confirmed orders in the selected period</span>
        </div>
      </div>
      <div class="table-component w-100 top-product custom-scroll">
        <app-table-view
          (onClick)="onActionClick($event)"
          [table_schema]="orderTable"
          [loader]="orderLoader$ | async"
          [errorMessage]="orderErrorMessage$ | async"></app-table-view>
      </div>
      <footer class="layout-footer sticky-footer">
        <div class="ce-row">
          <div class="ce-col-12 pt-0 pb-0">
            <!-- Footer -->
            <app-list-footer [pagination]="pagination" (onPageChange)="onPageChange($event)">
            </app-list-footer>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
