import { Injectable } from '@angular/core';
import { DynamicQueryService } from '@services/analytics-services/core/dynamic-query.service';
import {
  AnalyticsRangeContext,
  DynamicMetricsInfo,
  DynamicQueryContext,
} from '@services/analytics-services/core';
import { Observable } from 'rxjs';
import { PaginatedList } from '@services/core-services/models';

const LIMIT = 1000;

@Injectable({ providedIn: 'root' })
export class SalesAnalyticsService {
  constructor(private readonly dynamicQueryService: DynamicQueryService) {}

  getNetAndGrossSalesMetrics(
    range: AnalyticsRangeContext
  ): Observable<PaginatedList<DynamicMetricsInfo[]>> {
    const context: DynamicQueryContext = {
      query: {
        limit: LIMIT,
        offset: 0,
        filters: [
          {
            value: 'success',
            operator: 'equal',
            field_name: 'payment_status',
          },
          {
            value: 'cancelled',
            operator: 'not-equal',
            field_name: 'status',
          },
        ],
        aggregate: [
          {
            type: 'SUM',
            alias: 'net_sales',
            field_name: 'sub_total',
          },
          {
            type: 'SUM',
            alias: 'gross_sales',
            field_name: 'sub_total_including_tax',
          },
        ],
      },
      query_type: 'metrics',
      schema_name: 'ce_replica.`ce_sales.sales_order`',
    };

    return this.dynamicQueryService.fetchWithCompare(range, context);
  }

  getSalesByTrafficSource(
    range: AnalyticsRangeContext
  ): Observable<PaginatedList<DynamicMetricsInfo>> {
    const context: DynamicQueryContext = {
      query: {
        limit: LIMIT,
        offset: 0,
        filters: [
          {
            value: 'success',
            operator: 'equal',
            field_name: 'payment_status',
          },
          {
            value: 'cancelled',
            operator: 'not-equal',
            field_name: 'status',
          },
        ],
        aggregate: [
          {
            type: 'COUNT',
            alias: 'order_count',
            field_name: 'id',
          },
          {
            type: 'SUM',
            alias: 'net_sales',
            field_name: 'sub_total',
          },
          {
            type: 'SUM',
            alias: 'gross_sales',
            field_name: 'sub_total_including_tax',
          },
        ],
        group_by: {
          fields: 'channel_id',
        },
      },
      query_type: 'metrics',
      schema_name: 'ce_replica.`ce_sales.sales_order`',
    };

    return this.dynamicQueryService.fetch(range, context);
  }

  getOrdersOverTime(range: AnalyticsRangeContext) {
    const context: DynamicQueryContext = {
      query: {
        limit: LIMIT,
        offset: 0,
        filters: [
          {
            value: 'success',
            operator: 'equal',
            field_name: 'payment_status',
          },
          {
            value: 'complete',
            operator: 'equal',
            field_name: 'status',
          },
        ],
        aggregate: [
          {
            type: 'COUNT',
            alias: 'order_count',
            field_name: 'id',
          },
        ],
      },
      query_type: 'metrics',
      schema_name: 'ce_replica.`ce_sales.sales_order`',
    };

    return this.dynamicQueryService.fetch(range, context);
  }
}
