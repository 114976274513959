import { Component, Input, OnInit, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { CommonModule } from '@angular/common';
import { BarChartConfig } from '@services/analytics-services/hight-chart-configurations/bar-high-chart-config';
import { PieChartConfig } from '@services/analytics-services/hight-chart-configurations/pie-high-chart-config';
import { ChartOptions } from '@services/analytics-services/hight-chart-configurations/high-chart-config';

/**
 * Chart type enum
 */
export type ChartType = 'bar' | 'pie';

@Component({
  selector: 'app-high-chart-container',
  standalone: true,
  imports: [CommonModule, HighchartsChartModule],
  templateUrl: './high-chart-container.component.html',
  styleUrl: './high-chart-container.component.scss',
})
export class HighChartContainerComponent implements OnInit, OnChanges {
  @Input() chartOptions: ChartOptions = {};
  @Input() chartType: ChartType = 'bar';
  @Input() showLegend: boolean = false;
  @Input() colors: string[] = [];
  @Input() title: string = '';
  @Input() donut: boolean = false;
  @Input() emptyStateMessage: string = 'No data available';

  Highcharts: typeof Highcharts = Highcharts;
  options: Highcharts.Options = {};
  hasData: boolean = true;

  ngOnInit() {
    this.updateChartOptions();
  }

  updateChartOptions() {
    const isEmpty = !this.chartOptions?.data || this.chartOptions?.data.length === 0;
    this.hasData = !isEmpty;

    // Merge input properties into chartOptions
    const mergedOptions: ChartOptions = {
      ...this.chartOptions,
      showLegend: this.showLegend || this.chartOptions.showLegend,
      colors: this.colors.length ? this.colors : this.chartOptions.colors,
      title: this.title || this.chartOptions.title,
      donut: this.donut,
    };

    // Choose the appropriate chart configuration based on chartType
    switch (this.chartType) {
      case 'pie':
        this.options = PieChartConfig.getPieChartOptions(mergedOptions);
        break;
      default:
        this.options = BarChartConfig.getBarChartOptions(mergedOptions);
        break;
    }

    if (isEmpty) {
      this.options.lang = {
        ...this.options.lang,
        noData: this.emptyStateMessage,
      };

      this.options.noData = {
        ...this.options.noData,
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      };
    }
  }

  // Handle input changes
  ngOnChanges() {
    this.updateChartOptions();
  }
}
