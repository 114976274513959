import { Routes } from '@angular/router';
import { CampaignsTabComponent } from '../customer-module/customer-details/campaigns-tab/campaigns-tab.component';
import { LoyaltyTabComponent } from '../customer-module/customer-details/loyalty-tab/loyalty-tab.component';
import { DashboardOverviewComponent } from './dashboard-overview/dashboard-overview.component';
import { DashboardCustomersComponent } from './dashboard-customers/dashboard-customers.component';
import { DashboardProductsComponent } from './dashboard-products/dashboard-products.component';
import { DashboardPaymentsComponent } from './dashboard-payments/dashboard-payments.component';
import { DashboardShipmentComponent } from './dashboard-shipment/dashboard-shipment.component';
import { DashboardOrdersComponent } from './dashboard-orders/dashboard-orders.component';
import { DashboardInsightsComponent } from './dashboard-insights/dashboard-insights.component';
import { DashboardSalesComponent } from './dashboard-sales/dashboard-sales.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./dashboard/dashboard.component').then(c => c.DashboardComponent),
    title: 'Dashboard',
    children: [
      {
        path: 'overview',
        component: DashboardOverviewComponent,
        title: 'Dashboard | Overview',
        data: { key: 'overview' },
      },
      {
        path: 'orders',
        component: DashboardOrdersComponent,
        title: 'Dashboard | Orders',
      },
      {
        path: 'revenue',
        component: DashboardSalesComponent,
        title: 'Dashboard | Revenue & Sales',
      },
      {
        path: 'top-products',
        component: DashboardProductsComponent,
        title: 'Dashboard | Top Products',
      },
      {
        path: 'campaigns',
        component: LoyaltyTabComponent,
        title: 'Dashboard | Marketing & Campaigns',
      },
      {
        path: 'customers',
        component: DashboardCustomersComponent,
        title: 'Dashboard | Customers',
      },
      {
        path: 'inventory',
        component: CampaignsTabComponent,
        title: 'Dashboard | Inventory',
      },
      {
        path: 'shipment',
        component: DashboardShipmentComponent,
        title: 'Dashboard | Shipment',
      },
      {
        path: 'payments',
        component: DashboardPaymentsComponent,
        title: 'Dashboard | Payments',
      },
      {
        path: 'insights',
        component: DashboardInsightsComponent,
        title: 'Dashboard | Insights & Analytics',
      },
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
    ],
  },
] as Routes;
