import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { AnalyticsWidgetComponent } from '@enginuity/analytics/organisms/analytics-widget/analytics-widget.component';
import { AnalyticsRangeContext, ComparePeriod } from '@services/analytics-services/core';
import { DashboardStore } from '../dashboard/dashboard.store';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';

@Component({
  selector: 'app-dashboard-overview',
  imports: [AnalyticsWidgetComponent, AsyncPipe, AnalyticsTrendWidgetComponent],
  templateUrl: './dashboard-overview.component.html',
  styleUrl: './dashboard-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardOverviewComponent {
  protected productAnalytics$;
  protected customerAnalytics$;
  protected orderAnalytics$;
  protected comparePeriod!: ComparePeriod;
  protected context!: AnalyticsRangeContext;

  constructor(private readonly dashboardStore: DashboardStore) {
    this.dashboardStore.getComparePeriod().subscribe(period => (this.comparePeriod = period));
    this.dashboardStore.getRangeContext().subscribe(context => {
      this.context = context;
      this.fetchAnalytics();
    });

    this.productAnalytics$ = this.dashboardStore.getProductAnalytics();
    this.customerAnalytics$ = this.dashboardStore.getCustomerAnalytics();
    this.orderAnalytics$ = this.dashboardStore.getOrderAnalytics();
  }

  private fetchAnalytics() {
    this.dashboardStore.loadProductsAnalytics().subscribe();
    this.dashboardStore.loadCustomerAnalytics().subscribe();
    this.dashboardStore.loadOrderAnalytics().subscribe();
  }
}
