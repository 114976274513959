import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import {
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  registerables,
  ChartConfiguration,
} from 'chart.js';
import { BarChartContainerComponent } from '@enginuity/analytics/organisms/bar-chart-container/bar-chart-container.component';
import { GroupChartTabs, Pagination } from '@services/analytics-services/core/group-chart.modals';

ChartJS.register(...registerables);

@Component({
  selector: 'app-chart-container',
  imports: [BaseChartDirective, BarChartContainerComponent],
  templateUrl: './chart-container.component.html',
  styleUrl: './chart-container.component.scss',
  providers: [provideCharts(withDefaultRegisterables())],
})
export class ChartContainerComponent {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  @Output() tabChange = new EventEmitter<string>();
  @Output() pageChange = new EventEmitter<{ tabId: string; page: number; limit: number }>();

  @Input() width: number | 'auto' = 'auto';
  @Input() height: number | 'auto' = 'auto';
  @Input() type: any = 'line';
  @Input() datasets: ChartDataset<any>[] = [];
  @Input() data: ChartData | undefined = undefined;
  @Input() labels: any[] = [];
  @Input() DataLabel: string = '';
  @Input() title: string = '';
  @Input() multipleTitles: string[] = [];
  @Input() options: ChartConfiguration['options'] = {
    plugins: {},
  };
  @Input() legend: boolean = false;
  @Input() statsData: any[] = [];
  @Input() statsMap: { [tabId: string]: any[] } = {};
  @Input() badgeType: string = 'white';
  @Input() chartType: 'bar' | undefined;
  @Input() paginationData: Pagination = {
    limit: 10,
    next_page: null,
    total_pages: 1,
    previous_page: null,
    total_records: 0,
    current_page: 1,
  };
  @Input() showPagination: boolean = false;
  @Input() tabs: GroupChartTabs[] = [
    { id: 'overview', label: 'Overview' },
    { id: 'details', label: 'Details' },
    { id: 'analytics', label: 'Analytics' },
  ];
  @Input() defaultActiveTab: string = '';

  get mergedOptions(): ChartConfiguration['options'] {
    return {
      ...this.options,
      plugins: {
        ...this.options?.plugins,
      },
    };
  }

  onTabChange(tabId: string) {
    this.defaultActiveTab = tabId; // Update the default active tab
    this.tabChange.emit(tabId);
  }

  onPageChange(event: { tabId: string; page: number; limit: number }) {
    this.pageChange.emit(event);
  }
}
