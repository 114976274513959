import { ProductPerformanceStats } from '../models/product-performance-stats';
import { productPerformanceList } from './product-performance-table-header';

type TopProductTableRow = ProductPerformanceStats & {
  isSelected?: boolean;
};

export const mapProductPerformaceTableData = (products: TopProductTableRow) => {
  let index = 0;
  return [
    {
      column: productPerformanceList[index++].column,
      type: 'product_details',
      product_details: {
        product_img: products.image_url,
        product_name: products.sku_name,
      },
    },
    {
      column: productPerformanceList[index++].column,
      type: 'text',
      text: {
        title: products?.total_orders,
      },
    },
    {
      column: productPerformanceList[index++].column,
      type: 'text',
      text: {
        title: products?.unique_customers,
      },
    },

    {
      column: productPerformanceList[index++].column,
      type: 'text',
      text: {
        title: products?.repeat_customers,
      },
    },
    {
      column: productPerformanceList[index++].column,
      type: 'text',
      text: {
        title: products?.repeat_customer_rate,
      },
    },
    {
      column: productPerformanceList[index++].column,
      type: 'text',
      text: {
        title: products?.avg_days_between_orders,
      },
    },
    {
      column: productPerformanceList[index++].column,
      type: 'actions',
      label: '',
      actions: [
        {
          icon: 'chevron_right',
          action: 'product-detail',
          text: '',
          active: false,
          productId: products.product_id,
        },
      ],
    },
  ];
};
