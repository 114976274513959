import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsRangeContext, ComparePeriod } from '@services/analytics-services/core';
import { AnalyticsWidgetHostComponent } from '@enginuity/analytics/organisms/analytics-widget-host/analytics-widget-host.component';
import { DashboardStore } from '../dashboard/dashboard.store';
import { Subject, takeUntil, tap } from 'rxjs';
import { InsightsAnalyticsCharts } from '@services/analytics-services/insights/insights-analytics.charts';
import { InsightsAnalyticsService } from '@services/analytics-services/insights/insights-analytics.service';
import { ChartContainerComponent } from '@enginuity/analytics/organisms/chart-container/chart-container.component';
import { Chart, ChartConfiguration, ChartTypeRegistry, registerables } from 'chart.js';
import { barChartConfig } from '@services/analytics-services/core/bar-chart-configuration';
import { CommonModule } from '@angular/common';
import { HighChartContainerComponent } from '@enginuity/analytics/organisms/high-chart-container/high-chart-container.component';
import { ChartOptions } from '@services/analytics-services/hight-chart-configurations/high-chart-config';
import { DEVICE_COLORS } from '@services/analytics-services/hight-chart-configurations';
import { TrafficMatrixComponent } from '@enginuity/analytics/molecules/traffic-matrix/traffic-matrix.component';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';
import { AnalyticsWidgetComponent } from '@enginuity/analytics/organisms/analytics-widget/analytics-widget.component';
import {
  GroupChartTabs,
  ChartDataPoint,
  GroupedTabData,
} from '@services/analytics-services/core/group-chart.modals';

// Register chart types once at component level
Chart.register(...registerables);

// Global chart configuration
const customTooltipOptions = barChartConfig.getCustomChartOptions()?.plugins?.tooltip || {};

const GLOBAL_CHART_CONFIG = {
  responsive: true,
  animation: false as const,
  hover: {
    mode: 'nearest' as const,
    intersect: false,
  },
  plugins: {
    tooltip: {
      ...customTooltipOptions,
      animation: false as const,
      enabled: false,
      shared: true,
      position: 'nearest' as const,
      mode: 'nearest' as const,
      intersect: false,
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        boxHeight: 8,
        padding: 15,
        font: {
          size: 12,
        },
      },
    },
  },
};

@Component({
  selector: 'app-dashboard-insights',
  imports: [
    CommonModule,
    HighChartContainerComponent,
    AnalyticsWidgetHostComponent,
    ChartContainerComponent,
    TrafficMatrixComponent,
    AnalyticsTrendWidgetComponent,
    AnalyticsWidgetComponent,
  ],
  templateUrl: './dashboard-insights.component.html',
  styleUrl: './dashboard-insights.component.scss',
  providers: [InsightsAnalyticsCharts],
})
export class DashboardInsightsComponent implements OnInit, OnDestroy {
  protected context!: AnalyticsRangeContext;
  protected pageVisits: any;
  protected comparePeriod!: ComparePeriod;
  protected eventAnalytics$;
  protected viewAnalytics$;
  protected visitorAnalytics$;
  protected visitorsChartConfig: ChartConfiguration<keyof ChartTypeRegistry> | undefined;
  protected visitorsByDeviceChart: ChartConfiguration<keyof ChartTypeRegistry> | undefined;
  protected bounceRateChart: ChartConfiguration<keyof ChartTypeRegistry> | undefined;
  protected sessionStatChart: ChartConfiguration<keyof ChartTypeRegistry> | undefined;
  protected sessionEventsStatChart: any;
  protected serverEventsStatChart: ChartConfiguration<keyof ChartTypeRegistry> | undefined;
  protected activeUsers: number = 0;
  protected trafficMatrixDatas: any[] = [];
  groupedVisitors: GroupedTabData = {};
  visitorsTabs: GroupChartTabs[] = [];
  defaultActiveTab: string = 'source';
  visitorMetricTitles: string[] = ['Visitors'];
  protected visitorsPagination: any = {
    limit: 10,
    next_page: null,
    total_pages: 1,
    previous_page: null,
    total_records: 0,
    current_page: 1,
  };
  protected visitorsPageSize: number = 8;
  protected visitorsAllData: GroupedTabData = {}; // To store all data before pagination

  protected trafficMatrixData: {
    day: string;
    hour: number;
    total_event_count: number;
    unique_user_count: number;
  }[] = [];
  protected days: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  protected pageStats: any[] = [];
  protected Visitors: any[] = [];
  protected sessionStats: any[] = [];
  protected pageStatsData: ChartOptions = {};
  protected visitorsByDevice: ChartOptions = {};
  protected serverEvents: ChartOptions = {};
  protected sessionChartOptions: ChartOptions = {};

  protected maxEventsCount: number = 0;

  private destroy$ = new Subject<void>();
  protected tooltipVisible: boolean = false;
  protected tooltipData: { label: string; value: string } | null = null;
  protected tooltipPosition: { x: number; y: number } = { x: 0, y: 0 };
  constructor(
    private readonly insightsAnalyticsCharts: InsightsAnalyticsCharts,
    private readonly insightsAnalyticsService: InsightsAnalyticsService,
    private readonly dashboardStore: DashboardStore
  ) {
    // Set global chart defaults
    Chart.defaults.set(GLOBAL_CHART_CONFIG);
    this.visitorAnalytics$ = this.dashboardStore.getVisitorAnalytics();
    this.viewAnalytics$ = this.dashboardStore.getViewAnalytics();
    this.eventAnalytics$ = this.dashboardStore.getEventAnalytics();
  }

  ngOnInit() {
    this.dashboardStore
      .getComparePeriod()
      .pipe(takeUntil(this.destroy$))
      .subscribe(period => (this.comparePeriod = period));

    this.dashboardStore
      .getRangeContext()
      .pipe(takeUntil(this.destroy$))
      .subscribe(context => {
        this.context = context;
        this.fetchAnalytics();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fetchAnalytics() {
    this.dashboardStore.loadVisitorAnalytics().subscribe();
    this.dashboardStore.loadViewAnalytics().subscribe();
    this.dashboardStore.loadVEventAnalytics().subscribe();
    this.insightsAnalyticsService
      .getWebsiteTraffic(this.context)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ list }) => {
        this.trafficMatrixDatas = list || [];
      });

    this.insightsAnalyticsService
      .getVisitors(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap(response => {
          try {
            const list = 'list' in response ? response.list : response;

            if (!Array.isArray(list) || list.length === 0) {
              this.Visitors = [];
              this.groupedVisitors = {};
              this.visitorsTabs = [];
              this.defaultActiveTab = '';
              this.visitorsAllData = {};
              return;
            }

            const fieldsPresence: { [key: string]: boolean } = {
              source: false,
              utm_source: false,
              campaign: false,
              medium: false,
            };

            list.forEach((item: any) => {
              if (item.source) fieldsPresence['source'] = true;
              if (item.utm_source) fieldsPresence['utm_source'] = true;
              if (item.campaign) fieldsPresence['campaign'] = true;
              if (item.medium) fieldsPresence['medium'] = true;
            });

            this.visitorsTabs = [
              { id: 'source', label: 'Source' },
              { id: 'utm_source', label: 'UTM Source' },
              { id: 'campaign', label: 'Campaign' },
              { id: 'medium', label: 'Medium' },
            ].filter(tab => fieldsPresence[tab.id]);

            this.defaultActiveTab = fieldsPresence['source']
              ? 'source'
              : this.visitorsTabs[0]?.id || '';

            this.visitorsAllData = {};

            this.visitorsTabs.forEach(tab => {
              this.visitorsAllData[tab.id] = [];
            });

            // Process data for each tab
            list.forEach((item: any) => {
              for (const field of ['source', 'utm_source', 'campaign', 'medium']) {
                if (item[field] && fieldsPresence[field]) {
                  const value = item[field];
                  const formattedLabel = value.charAt(0).toUpperCase() + value.slice(1);

                  const dataPoint: ChartDataPoint = {
                    label: formattedLabel,
                    value: item.unique_users,
                    originalData: {
                      ...item,
                      unique_users: item.unique_users,
                    },
                    metrics: [
                      {
                        title: 'Visitors',
                        value: item.unique_users,
                      },
                    ],
                  };

                  if (
                    !this.visitorsAllData[field].some(existing => existing.label === formattedLabel)
                  ) {
                    this.visitorsAllData[field].push(dataPoint);
                  } else {
                    const existingItem = this.visitorsAllData[field].find(
                      existing => existing.label === formattedLabel
                    );
                    if (existingItem) {
                      existingItem.value += item.unique_users;
                      // Update the metrics value as well
                      if (existingItem['metrics'] && existingItem['metrics'].length > 0) {
                        existingItem['metrics'][0].value += item.unique_users;
                      }
                    }
                  }
                }
              }
            });

            // Sort the data by value for all tabs
            for (const key in this.visitorsAllData) {
              this.visitorsAllData[key].sort((a, b) => b.value - a.value);
            }

            this.groupedVisitors = {};
            this.visitorsTabs.forEach(tab => {
              this.updateVisitorsPagination(tab.id, 1);
            });

            this.Visitors = this.defaultActiveTab
              ? this.groupedVisitors[this.defaultActiveTab]
              : [];
          } catch (error) {
            console.error('Error processing visitors data:', error);
            this.Visitors = [];
            this.groupedVisitors = {};
            this.visitorsTabs = [];
            this.defaultActiveTab = '';
            this.visitorsAllData = {};
          }
        })
      )
      .subscribe();

    this.insightsAnalyticsService
      .getVisitorsByDevice(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap(response => {
          const list = response?.list || [];

          if (list.length === 0) {
            this.visitorsByDevice = { data: [] };
            return;
          }

          const deviceData = list.map((item: { device: string; unique_users: number }) => {
            const deviceType = (item.device || 'unknown').toLowerCase();
            return {
              label: item.device || 'Unknown',
              y: item.unique_users,
              color: DEVICE_COLORS[deviceType] || undefined,
            };
          });

          const totalVisitors = deviceData.reduce((sum: any, item: any) => sum + item.y, 0);

          deviceData.sort((a: { y: number }, b: { y: number }) => b.y - a.y);

          this.visitorsByDevice = {
            data: deviceData,
            showLegend: true,
            donut: true,
            title: 'Device Distribution',
            tooltipFormatter: (point: Highcharts.Point) => `
              <div class="custom-chart-tooltip" style="padding:12px;">
                <div class="d-flex flex-column spacing-8 align-items-start w-100">
                  <h4 class="h4-bold primary-0" style="margin: 0">${point.name}</h4>
                  <span class="dash-boarder w-100"></span>
                  <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                    <div class="d-flex align-items-center spacing-4 w-100 text-center">
                      <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold">&nbsp;</div>
                      <div class="h4-reg primary-50">Unique Visitors</div>
                    </div>
                    <div class="h4-bold primary-0">${point.y} (${point.percentage?.toFixed(1)}%)</div>
                  </div>
                </div>
              </div>
            `,
            donutOptions: {
              centerText: {
                text: `${totalVisitors.toLocaleString()}`,
                subtext: 'Total Visitors',
              },
            },
          };
        })
      )
      .subscribe();

    this.insightsAnalyticsCharts
      .getBounceRateChart(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap((chart: ChartConfiguration<keyof ChartTypeRegistry>) => {
          this.bounceRateChart = {
            ...chart,
            options: {
              ...chart.options,
              interaction: {
                mode: 'nearest',
                intersect: false,
              },
              plugins: {
                ...chart.options?.plugins,
                tooltip: {
                  ...GLOBAL_CHART_CONFIG.plugins.tooltip,
                  callbacks: {
                    label: context => {
                      const label = context.label || '';
                      const value = context.formattedValue;
                      return ` ${label}: ${value}`;
                    },
                  },
                },
              },
            },
          };
        })
      )
      .subscribe();

    this.insightsAnalyticsService
      .getServerEventsStat(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap((list: any = []) => {
          if (!list || list.length === 0) {
            this.serverEvents = { data: [] };
            return;
          }

          const serveEventeData = list.map((item: { event_name: string; events_count: number }) => {
            const deviceType = (item.event_name || 'unknown').toLowerCase();
            return {
              label: item.event_name || 'Unknown',
              y: item.events_count,
              color: DEVICE_COLORS[deviceType] || undefined, // Use matched color or let Highcharts decide
            };
          });

          const totalEvents = serveEventeData.reduce(
            (sum: number, item: { y: number }) => sum + item.y,
            0
          );

          this.serverEvents = {
            data: serveEventeData,
            showLegend: true,
            donut: true,
            title: 'Device Distribution',
            tooltipFormatter: (point: Highcharts.Point) => `
          <div class="custom-chart-tooltip" style="padding:12px;">
            <div class="d-flex flex-column spacing-8 align-items-start w-100">
              <h4 class="h4-bold primary-0" style="margin: 0">${point.name}</h4>
              <span class="dash-boarder w-100"></span>
              <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                <div class="d-flex align-items-center spacing-4 w-100 text-center">
                  <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold"></div>
                  <div class="h4-reg primary-50">Event Counts</div>
                </div>
                <div class="h4-bold primary-0">${point.y} (${point.percentage?.toFixed(1)}%)</div>
              </div>
            </div>
          </div>
        `,
            donutOptions: {
              centerText: {
                text: `${totalEvents.toLocaleString()}`,
                subtext: 'Total Events',
              },
            },
          };
        })
      )
      .subscribe();
    this.insightsAnalyticsService
      .getPageStat(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap((data: { event_name: string; events_count: number }[] = []) => {
          if (!data || data.length === 0) {
            this.pageStats = [];
            this.pageStatsData = { data: [] };
            return;
          }

          // Map event_name and events_count to label and value
          this.pageStats = data.map(stat => ({
            label: stat.event_name,
            value: stat.events_count,
          }));

          this.pageStatsData = {
            categories: this.pageStats.map(stat => stat.label ?? 'null'),
            data: this.pageStats.map(stat => ({
              y: stat.value,
              label: stat.label,
            })),
            yAxisTitle: 'Number of Pages',
            tooltipFormatter: (point: Highcharts.Point) => `
            <div class="custom-chart-tooltip" style="padding:12px;">
              <div class="d-flex flex-column spacing-8 align-items-start w-100">
                <h4 class="h4-bold primary-0" style="margin: 0">${point.options.label}</h4>
                <span class="dash-boarder w-100"></span>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                    <div class="d-flex align-items-center spacing-4 w-100 text-center">
                      <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold">&nbsp;</div>
                      <div class="h4-reg primary-50">Page Visits</div>
                    </div>
                    <div class="h4-bold primary-0">${point.options.y}</div>
                </div>
              </div>
            </div>
          `,
          };
        })
      )
      .subscribe();

    this.insightsAnalyticsService
      .getSessionsStat(this.context)
      .pipe(
        takeUntil(this.destroy$),
        tap((data: { origin_source: string; session_count: number }[]) => {
          this.sessionStats = data.map(stat => ({
            label: stat.origin_source,
            value: stat.session_count,
          }));

          this.sessionChartOptions = {
            categories: this.sessionStats.map(stat => stat.label ?? 'null'),
            data: this.sessionStats.map(stat => ({
              y: stat.value,
              label: stat.label,
            })),
            yAxisTitle: 'Number of Sessions',
            tooltipFormatter: (point: Highcharts.Point) => `
            <div class="custom-chart-tooltip" style="padding:12px;">
              <div class="d-flex flex-column spacing-8 align-items-start w-100">
                <h4 class="h4-bold primary-0 text-capitalize" style="margin: 0">${point.options.label}</h4>
                <span class="dash-boarder w-100"></span>
                <div class="d-flex align-items-center justify-content-between spacing-8 w-100">
                    <div class="d-flex align-items-center spacing-4 w-100 text-center">
                      <div style="background:${point.color}" class="tooltipDot d-flex align-items-center h4-bold">&nbsp;</div>
                      <div class="h4-reg primary-50">Session Count</div>
                    </div>
                    <div class="h4-bold primary-0">${point.options.y}</div>
                </div>
              </div>
            </div>
          `,
          };
        })
      )
      .subscribe();

    this.insightsAnalyticsService
      .getActiveUsers(this.context)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.activeUsers = data.active_users;
      });
  }
  protected onVisitorsTabChange(tabId: string): void {
    this.defaultActiveTab = tabId; // Update the default active tab
    this.updateVisitorsPagination(tabId, 1);
  }

  protected onVisitorsPageChange(event: { tabId: string; page: number; limit: number }): void {
    this.updateVisitorsPagination(event.tabId, event.page, event.limit);
  }

  private updateVisitorsPagination(
    tabId: string,
    page: number,
    limit: number = this.visitorsPageSize
  ): void {
    const allDataForTab = this.visitorsAllData[tabId] || [];
    const totalRecords = allDataForTab.length;
    const totalPages = Math.ceil(totalRecords / limit);

    // Update pagination info
    this.visitorsPagination = {
      limit,
      current_page: page,
      total_records: totalRecords,
      total_pages: totalPages,
      next_page: page < totalPages ? page + 1 : null,
      previous_page: page > 1 ? page - 1 : null,
    };
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;

    // Get the data for this page
    const paginatedData = allDataForTab.slice(startIndex, endIndex);

    // Ensure each data point has metrics and originalData
    const enhancedData = paginatedData.map(item => {
      // If the item already has metrics, use them
      if (item['metrics']) {
        return item;
      }

      // Otherwise, create metrics based on the visitor count
      return {
        ...item,
        originalData: item['originalData'] || {
          unique_users: item.value,
        },
        metrics: [
          {
            title: 'Visitors',
            value: item.value,
          },
        ],
      };
    });

    this.groupedVisitors[tabId] = enhancedData;
  }
}
