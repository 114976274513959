import { SubsequentPurchasesList } from './dashboard-subsequent-purchase-table-header.constant';
import { SubsequentPurchasesStats } from './subsequest-purchases-stats';

type CustomerTableRow = SubsequentPurchasesStats & {
  isSelected?: boolean;
};

export const mapSubsequentPurchaseTableData = (customer: CustomerTableRow) => {
  let index = 0;
  return [
    {
      column: SubsequentPurchasesList[index++].column,
      type: 'customer',
      label: 'Name',
      customer: {
        name: customer?.name,
      },
    },
    {
      column: SubsequentPurchasesList[index++].column,
      type: 'text',
      text: {
        title: customer.order_count,
      },
    },
    {
      column: SubsequentPurchasesList[index++].column,
      type: 'text',
      text: {
        title: customer.avg_days_between_orders,
      },
    },
    {
      column: SubsequentPurchasesList[index++].column,
      type: 'actions',
      label: '',
      actions: [
        {
          icon: 'chevron_right',
          action: 'user-detail',
          text: '',
          active: false,
          customerId: customer.user_id,
        },
      ],
    },
  ];
};
